import { createContext } from 'react';

import { BodilColor } from '@src/branding';

export type BrandingContext = Partial<{
  logoURL: string;
  contrastLogoURL: string;
  darkColor: string;
  lightColor: string;
  darkTextColor: string;
  lightTextColor: string;
}>;

const brandingContext = createContext<BrandingContext>({});

export const BrandingContextProvider = brandingContext.Provider;

export const useBranding = () => {
  return {
    logoURL: '/logo/bodil-dark.png',
    contrastLogoURL: '/logo/bodil-logo-contrast.png',
    darkColor: BodilColor.base.default,
    lightColor: BodilColor.ui.action.default,
    darkTextColor: BodilColor.base.default,
    lightTextColor: BodilColor.base.white,
  };
};

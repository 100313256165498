import styled from 'styled-components';

import { CalculatorFooter } from '@src/components/Footer/CalculatorFooter';
import { Root } from '@src/components/Root';

const Container = styled.div({
  display: 'flex',
  flexFlow: 'column nowrap',
  margin: `40px 0`,
  height: 'calc(100vh - 445px)',
  width: '100%',
});

const UnexpectedErrorPage = () => {
  return (
    <Root footer={<CalculatorFooter />}>
      <Container>
        <h1>Der skete desværre en fejl</h1>
        <p>
          Vi beklager ulejligheden. Prøv at indlæse <a href='/'>Bodil Energi</a> igen.
        </p>
        <p>Hvis fejlen opstår igen, kan du kontakte os via oplysningerne i bunden af siden.</p>
        <p>Mvh Bodil Energi</p>
      </Container>
    </Root>
  );
};

export default UnexpectedErrorPage;

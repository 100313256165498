import sumBy from 'lodash/sumBy';

import { DeepPartial, NewOfferExpensesInput, Offer, OfferExpenseTemplate, SolarPanel } from '@src/model';

export const formatProductText = (product?: Pick<SolarPanel, 'brand' | 'model'>) => {
  const { brand = '', model = '' } = product ?? {};
  return `${brand} ${model}`.trim();
};

type OfferInstallationExpense = Pick<OfferExpenseTemplate, 'type' | 'minimumProducts' | 'price'>;

export const findInstallationExpensesForOffer = (
  offer?: Pick<DeepPartial<Offer>, 'heatPumpOutdoorUnit' | 'solarPanel' | 'battery'>,
  installationExpenses?: { heatPump: OfferInstallationExpense[]; solar: OfferInstallationExpense[] }
) => {
  if (!offer || !installationExpenses) {
    return [];
  }

  const { heatPump, solar: solarExpenses } = installationExpenses;
  const { heatPumpOutdoorUnit, solarPanel, battery } = offer;

  if (!solarPanel) {
    const heatPumpInstallationTypes = new Set(['indoorUnitInstallation', 'outdoorUnitInstallation']);

    return heatPump.filter(({ type }) => heatPumpInstallationTypes.has(type ?? ''));
  }

  const { quantity = 0 } = solarPanel ?? {};

  const hasBatteries = !!battery;
  const solarInstallationTypes = new Set(['inverterInstallation', ...(hasBatteries ? ['batteryInstallation'] : [])]);

  const solarPanelExpense = [...solarExpenses]
    .sort((a, b) => (b.minimumProducts ?? 0) - (a.minimumProducts ?? 0))
    .find(({ minimumProducts = 0, type }) => quantity >= (minimumProducts ?? 0) && type === 'solarPanelInstallation');

  const solar = [
    ...solarExpenses.filter(({ type }) => solarInstallationTypes.has(type ?? '')),
    ...(solarPanelExpense ? [{ ...solarPanelExpense, price: (solarPanelExpense.price ?? 0) * quantity }] : []),
  ];

  if (!heatPumpOutdoorUnit) {
    return solar;
  }

  return [...heatPump, ...solar];
};

export const newOfferPriceWithExpenses = (
  offer?: Pick<
    DeepPartial<Offer>,
    'heatPumpOutdoorUnit' | 'heatPumpIndoorUnit' | 'inverter' | 'solarPanel' | 'battery'
  >,
  expenses?: NewOfferExpensesInput
): number => {
  if (isEmptyOffer(offer)) {
    return 0;
  }

  const matchedExpenses = findInstallationExpensesForOffer(offer, expenses);
  const installationPrice = sumBy(matchedExpenses, 'price');

  const includedSolarExpenses = offer?.solarPanel
    ? sumBy(expenses?.solar.filter(({ includedByDefault }) => includedByDefault) ?? [], 'price')
    : 0;

  const includedHeatPumpExpenses = offer?.heatPumpOutdoorUnit
    ? sumBy(expenses?.heatPump.filter(({ includedByDefault }) => includedByDefault) ?? [], 'price')
    : 0;

  return installationPrice + includedSolarExpenses + includedHeatPumpExpenses;
};

const isEmptyOffer = (offer?: DeepPartial<Offer>): boolean =>
  [offer?.heatPumpIndoorUnit, offer?.heatPumpOutdoorUnit, offer?.battery, offer?.inverter, offer?.solarPanel].filter(
    product => !!product
  ).length === 0;

import { MINUS_12_TO_MINUS_7_RATIO } from '../constants/datasheet';
import { calculateHeatDistributionValue } from './heatPump';
const requiredTemperatures = {
    floor: 35,
    radiator: 55,
};
const noiseImpact = {
    small: 1000,
    medium: 2500,
    large: 4000,
};
export const calculateHeatPumpScores = (heatPumps, residence, heatingRequiredMinus12Degrees) => {
    const heatingRequired = heatingRequiredMinus12Degrees * MINUS_12_TO_MINUS_7_RATIO;
    const { noiseWeight } = residence;
    const minimumPrice = Math.min(...heatPumps.map(({ price }) => price));
    const lowestNoise = Math.min(...heatPumps.map(({ noiseLevel }) => noiseLevel ?? 0));
    const requiredTemperature = calculateHeatDistributionValue(requiredTemperatures, residence);
    const noiseFactor = noiseImpact[noiseWeight ?? 'small'];
    const { adequate, inadequate } = heatPumps.reduce((acc, pump) => {
        const effect = calculateHeatDistributionValue(pump.effect7, residence);
        const priceScore = pump.price - minimumPrice;
        const noiseScore = ((pump.noiseLevel ?? 0) - lowestNoise) * noiseFactor;
        const entry = { ...pump, score: priceScore + noiseScore, enabled: true };
        if (effect < heatingRequired || (pump.temperatureOut ?? 0) < requiredTemperature) {
            return { ...acc, inadequate: acc.inadequate.concat({ ...entry, enabled: false }) };
        }
        return { ...acc, adequate: acc.adequate.concat(entry) };
    }, { adequate: [], inadequate: [] });
    const bestUnavailable = inadequate.sort((a, b) => calculateHeatDistributionValue(b.effect7, residence) - calculateHeatDistributionValue(a.effect7, residence))[0];
    return {
        available: adequate.sort((a, b) => (a.score ?? 0) - (b.score ?? 0)),
        bestUnavailable,
    };
};

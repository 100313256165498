/**
 * All loans exported from this file, that are subject to combination,
 * must share ANNUAL_INSTALMENTS, INTEREST, and TOTAL_YEARS properties.
 * Otherwise combination errors will ensue.
 */
const NORDEA_HEATPUMP_ANNUAL_INSTALMENTS = 12;
const NORDEA_HEATPUMP_ANNUAL_INTEREST = 0.0355;
const NORDEA_HEATPUMP_COMMISSION_PERCENTAGE = 0;
const NORDEA_HEATPUMP_FEE = 0;
const NORDEA_HEATPUMP_LAND_REGISTRY_FEE = 0;
const NORDEA_HEATPUMP_LAND_REGISTRY_VARIABLE = 0;
const NORDEA_HEATPUMP_TOTAL_YEARS = 10;
const NORDEA_SOLAR_ANNUAL_INSTALMENTS = 12;
const NORDEA_SOLAR_ANNUAL_INTEREST = 0.0533;
const NORDEA_SOLAR_COMMISSION_PERCENTAGE = 0.01;
const NORDEA_SOLAR_FEE = 1000;
const NORDEA_SOLAR_LAND_REGISTRY_FEE = 1730;
const NORDEA_SOLAR_LAND_REGISTRY_VARIABLE = 0.0145;
const NORDEA_SOLAR_TOTAL_YEARS = 10;
const JYSKE_HEATPUMP_ANNUAL_INSTALMENTS = 12;
const JYSKE_HEATPUMP_ANNUAL_INTEREST = 0.026;
const JYSKE_HEATPUMP_COMMISSION_MAXIMUM = 0;
const JYSKE_HEATPUMP_COMMISSION_PERCENTAGE = 0;
const JYSKE_HEATPUMP_FEE = 0;
const JYSKE_HEATPUMP_LAND_REGISTRY_FEE = 0;
const JYSKE_HEATPUMP_LAND_REGISTRY_VARIABLE = 0;
const JYSKE_HEATPUMP_TOTAL_YEARS = 10;
const JYSKE_SOLAR_ANNUAL_INSTALMENTS = 12;
const JYSKE_SOLAR_ANNUAL_INTEREST = 0.026;
const JYSKE_SOLAR_COMMISSION_MAXIMUM = 0;
const JYSKE_SOLAR_COMMISSION_PERCENTAGE = 0;
const JYSKE_SOLAR_FEE = 0;
const JYSKE_SOLAR_LAND_REGISTRY_FEE = 1825;
const JYSKE_SOLAR_LAND_REGISTRY_VARIABLE = 0.0145;
const JYSKE_SOLAR_TOTAL_YEARS = 10;
export const NORDEA_SOLAR_LOAN = {
    annualInterest: NORDEA_SOLAR_ANNUAL_INTEREST,
    annualInstalments: NORDEA_SOLAR_ANNUAL_INSTALMENTS,
    commission: NORDEA_SOLAR_COMMISSION_PERCENTAGE,
    fee: NORDEA_SOLAR_FEE,
    landRegistryFee: NORDEA_SOLAR_LAND_REGISTRY_FEE,
    landRegistryVariable: NORDEA_SOLAR_LAND_REGISTRY_VARIABLE,
    totalYears: NORDEA_SOLAR_TOTAL_YEARS,
};
export const NORDEA_HEATPUMP_LOAN = {
    annualInterest: NORDEA_HEATPUMP_ANNUAL_INTEREST,
    annualInstalments: NORDEA_HEATPUMP_ANNUAL_INSTALMENTS,
    commission: NORDEA_HEATPUMP_COMMISSION_PERCENTAGE,
    fee: NORDEA_HEATPUMP_FEE,
    landRegistryFee: NORDEA_HEATPUMP_LAND_REGISTRY_FEE,
    landRegistryVariable: NORDEA_HEATPUMP_LAND_REGISTRY_VARIABLE,
    totalYears: NORDEA_HEATPUMP_TOTAL_YEARS,
};
export const JYSKE_HEATPUMP_LOAN = {
    annualInterest: JYSKE_HEATPUMP_ANNUAL_INTEREST,
    annualInstalments: JYSKE_HEATPUMP_ANNUAL_INSTALMENTS,
    commission: JYSKE_HEATPUMP_COMMISSION_PERCENTAGE,
    commissionMax: JYSKE_HEATPUMP_COMMISSION_MAXIMUM,
    fee: JYSKE_HEATPUMP_FEE,
    totalYears: JYSKE_HEATPUMP_TOTAL_YEARS,
    landRegistryFee: JYSKE_HEATPUMP_LAND_REGISTRY_FEE,
    landRegistryVariable: JYSKE_HEATPUMP_LAND_REGISTRY_VARIABLE,
};
export const JYSKE_SOLAR_LOAN = {
    annualInterest: JYSKE_SOLAR_ANNUAL_INTEREST,
    annualInstalments: JYSKE_SOLAR_ANNUAL_INSTALMENTS,
    commission: JYSKE_SOLAR_COMMISSION_PERCENTAGE,
    commissionMax: JYSKE_SOLAR_COMMISSION_MAXIMUM,
    fee: JYSKE_SOLAR_FEE,
    totalYears: JYSKE_SOLAR_TOTAL_YEARS,
    landRegistryFee: JYSKE_SOLAR_LAND_REGISTRY_FEE,
    landRegistryVariable: JYSKE_SOLAR_LAND_REGISTRY_VARIABLE,
};

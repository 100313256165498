import { ProductTypes } from '@src/model';

export const getOutputArrayFilteredByProductType = <T>(
  selectedProductTypes: ProductTypes,
  heatPumpOutput: T,
  solarOutput: T
): T[] => [
  ...(selectedProductTypes.includes('heatPump') ? [heatPumpOutput] : []),
  ...(selectedProductTypes.includes('solar') ? [solarOutput] : []),
];

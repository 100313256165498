import { calculateElectricitySellPrice } from "../../util";
import { DISCOUNT_ELECTRICITY_REQUIRED, ELECTRICITY_DISCOUNT, ELECTRICITY_MINIMUM_SELL_PRICE, PRICE_ELECTRICITY, SELL_PRICE_ELECTRICITY_REDUCTION, } from './datasheet';
export const HEAT_PUMP_AIR_TO_WATER_EFFICIENCY = 2;
export const HEAT_PUMP_AIR_TO_AIR_EFFICIENCY = 4;
const electricity = {
    calorific: 1,
    price: PRICE_ELECTRICITY,
    denominator: 'kWh',
    emission: 0.122,
    discount: ELECTRICITY_DISCOUNT,
    discountRequired: DISCOUNT_ELECTRICITY_REQUIRED,
    sellPriceReduction: SELL_PRICE_ELECTRICITY_REDUCTION,
    sellPrice: Math.max(calculateElectricitySellPrice(PRICE_ELECTRICITY, SELL_PRICE_ELECTRICITY_REDUCTION), ELECTRICITY_MINIMUM_SELL_PRICE),
    minimumSellPrice: ELECTRICITY_MINIMUM_SELL_PRICE,
};
export const fuelOptions = {
    districtHeating: { calorific: 1, price: 0.4, denominator: 'kWh', emission: 0.0612 },
    electricity,
    gas: { calorific: 11, price: 12, denominator: 'm3', emission: 0.2 },
    oil: { calorific: 10, price: 14, denominator: 'liter', emission: 0.285 },
    pallet: { calorific: 5000, price: 2500, denominator: 'pallet' },
    straw: { calorific: 4028, price: 350, denominator: 'ton', emission: 0 },
    wood: { calorific: 4860, price: 2100, denominator: 'ton', emission: 0 },
    wood_1: {
        kasserummeter: { calorific: 2499, price: 1083, denominator: 'm3 stablet træ' },
        skovrummeter: { calorific: 1750, price: 758, denominator: 'm3 løst træ' },
    },
    wood_2: {
        kasserummeter: { calorific: 1835, price: 917, denominator: 'm3 stablet træ' },
        skovrummeter: { calorific: 1284, price: 642, denominator: 'm3 løst træ' },
    },
    inflation: { years: 15, rate: 0.02 },
};

import PageTransition from 'gatsby-plugin-page-transitions';
import isBoolean from 'lodash/isBoolean';
import { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { DESKTOP_FOOTER_HEIGHT, HEADER_HEIGHT, MOBILE_FOOTER_HEIGHT } from '@src/branding/constants';
import { defaultSideMargin, PageMargin } from '@src/branding/spacing';
import { useDisplayContext } from '@src/context/display';
import { EnergyOptionsContextProvider, useEnergyOptions } from '@src/context/energyOptions';
import { useRootStyle } from '@src/hooks/useRootStyle';
import { Address } from '@src/model';

import { Header } from './Header';

import 'antd/dist/antd.css';

const backgroundImageStyle: CSSProperties = {
  content: '""',
  backgroundSize: 'cover',
  position: 'absolute',
  top: HEADER_HEIGHT,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: -1,
  opacity: 0.25,
  backgroundAttachment: 'fixed',
};

const Page = styled.div<{
  isMobile?: boolean;
  padding?: number;
  backgroundStyle?: CSSProperties;
  bodyMargin?: PageMargin;
}>(({ bodyMargin = 'both', isMobile, padding, backgroundStyle }) => ({
  width: '100%',
  ...(backgroundStyle
    ? {
        position: 'relative',
        minHeight: '100vh',
        '&:before': {
          ...backgroundImageStyle,
          ...backgroundStyle,
        },
      }
    : { height: '100%', background: 'white' }),
  ...(isMobile ? { paddingLeft: padding, paddingRight: padding } : {}),
  ...(isMobile ? { paddingLeft: padding, paddingRight: padding } : defaultSideMargin(bodyMargin)),
}));

type Props = {
  children?: React.ReactNode;
  pagePreset?: 'column';
  pageStyle?: CSSProperties;
  wrapperStyle?: CSSProperties;
  header?: ReactNode;
  footer?: ReactNode;
  dashboard?: { address?: Address; back?: { to: string; label?: string } };
  flex?: boolean;
  bodyMargin?: PageMargin;
  headerMargin?: PageMargin;
  residenceID?: number | string;
  backgroundStyle?: CSSProperties;
};

export const Root = ({
  children,
  pageStyle,
  wrapperStyle,
  header = true,
  footer = true,
  bodyMargin,
  headerMargin = bodyMargin,
  backgroundStyle,
}: Props) => {
  const showDefaultHeader = isBoolean(header) && header;

  const energyOptions = useEnergyOptions();

  const { isMobile, height } = useDisplayContext();

  const { padding } = useRootStyle();

  const footerHeight = isMobile ? MOBILE_FOOTER_HEIGHT : DESKTOP_FOOTER_HEIGHT;
  const pageHeight = height - (footer ? footerHeight : 0);

  return (
    <PageTransition>
      <EnergyOptionsContextProvider value={{ options: energyOptions }}>
        {showDefaultHeader ? <Header margin={headerMargin} /> : header}
        <div
          style={{
            minHeight: pageHeight,
            display: 'flex',
            flexFlow: 'column wrap',
            ...wrapperStyle,
          }}
        >
          <Page
            {...{
              bodyMargin,
              style: { ...pageStyle, minHeight: pageHeight, ...(header && { paddingTop: HEADER_HEIGHT }) },
              isMobile,
              padding,
              backgroundStyle,
            }}
          >
            {children}
          </Page>
        </div>
        {footer}
      </EnergyOptionsContextProvider>
    </PageTransition>
  );
};

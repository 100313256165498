import findLastIndex from 'lodash/findLastIndex';
const years = [0, 1930, 1940, 1950, 1960, 1970, 1980, 1990, 2000, 2010];
const areas = [0, 80, 100, 120, 140, 160, 180, 200, 300];
const matrix = [
    [62.5, 56.8, 63.7, 62.8, 56.4, 48.1, 35.8, 37.1, 32.5, 18.3],
    [63.0, 57.3, 55.0, 58.2, 55.9, 47.7, 35.3, 37.6, 29.8, 17.0],
    [59.5, 54.1, 52.7, 54.5, 53.6, 44.0, 34.8, 34.8, 28.0, 15.6],
    [55.5, 50.4, 50.9, 52.3, 51.3, 44.0, 35.3, 35.3, 27.0, 15.1],
    [54.5, 49.5, 50.4, 50.4, 50.4, 44.5, 35.8, 35.8, 27.0, 15.1],
    [52.4, 47.7, 45.8, 48.6, 48.1, 43.5, 36.2, 35.8, 26.1, 14.2],
    [50.9, 46.3, 43.5, 46.8, 47.2, 42.6, 34.8, 33.5, 24.3, 13.3],
    [46.9, 42.6, 42.2, 41.3, 44.0, 40.3, 33.5, 32.1, 23.4, 12.8],
    [41.3, 37.6, 40.8, 40.8, 42.2, 38.0, 33.5, 34.4, 22.0, 12.8],
];
/**
 * Energy requirement without domestic water (W / m2) based on energy label statistics
 */
export const energyByYearAndArea = (yearBuilt, heatedArea) => {
    const yearBuiltIndex = findLastIndex(years, year => year <= yearBuilt);
    const areaIndex = findLastIndex(areas, area => area <= heatedArea);
    return matrix[areaIndex >= 0 ? areaIndex : 0][yearBuiltIndex >= 0 ? yearBuiltIndex : 0];
};

import { createContext, useContext } from 'react';

import { fuelOptions } from '@src/constants';
import { EnergyOptions } from '@src/model';

export type EnergyOptionsContext = { options: EnergyOptions };

const energyOptionsContext = createContext<EnergyOptionsContext>({ options: fuelOptions });

export const useEnergyOptionsContext = () => useContext<EnergyOptionsContext>(energyOptionsContext);
export const useEnergyOptions = () => useEnergyOptionsContext().options;

export const EnergyOptionsContextProvider = energyOptionsContext.Provider;

import noop from 'lodash/noop';
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { ToasterConfig } from '@src/model';

import { Toaster } from '../components/Toaster';

const TOASTER_TIME = 5000;

const Wrapper = styled.div({
  display: 'flex',
  flexFlow: 'column',
  position: 'fixed',
  top: '60px',
  right: '40px',
  width: 'fit-content',
  zIndex: 1e6,
});

type ToasterContext = {
  showToaster: (toaster: ToasterConfig) => void;
};

const ToasterContext = createContext<ToasterContext>({ showToaster: noop });

export const ToasterContextProvider = ({ children }: { children?: ReactNode }) => {
  const [toasters, setToasters] = useState<ToasterConfig[]>([]);

  const [timerHandle, setTimerHandle] = useState<NodeJS.Timeout>();

  useEffect(() => {
    clearTimeout(timerHandle);
    if (toasters.length > 0) {
      setTimerHandle(setTimeout(() => setToasters(toasters.slice(1)), TOASTER_TIME));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toasters.length]);

  const toasterContextValue: ToasterContext = useMemo(
    () => ({
      showToaster: (toaster: ToasterConfig) => {
        const { message } = toaster;
        if (toasters.some(inner => inner.message === message)) {
          return;
        }

        setToasters([...toasters, toaster]);
      },
    }),
    [toasters]
  );

  return (
    <ToasterContext.Provider value={toasterContextValue}>
      {children}
      <Wrapper>
        {toasters.map(toaster => (
          <Toaster {...toaster} key={toaster.message} />
        ))}
      </Wrapper>
    </ToasterContext.Provider>
  );
};

export const useToasters = () => useContext<ToasterContext>(ToasterContext).showToaster;

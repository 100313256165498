import { fuelMap } from '@src/constants/fuel';

export const APARTMENT_TAKEOVER_TEXT = `Ud fra dine offentlige oplysninger kan vi se, at du bor i en lejlighed. 
En investering i varmepumper eller solceller skal derfor besluttes i jeres ejendomsforening, 
og vi kan desværre ikke hjælpe med dette.`;

export const APARTMENT_TAKEOVER_SUBTEXT = `Hvis du ikke bor i lejlighed, 
kan du ændre din boligtype i boligdata eller kontakte en af vores rådgivere, 
for at høre om dine muligheder.`;

export const HIGH_USAGE_TAKEOVER_TEXT = (inputPossible?: boolean) => `Ud fra dine offentlige oplysninger${
  inputPossible ? ' eller din indtastning om forbrug' : ''
}, 
kan vi se, at energiforbruget i din bolig er så højt, at det vil kræve en specialløsning. 
Dette kan Bodil Energi sagtens hjælpe dig med, og vi anbefaler derfor du booker en tid med en af vores energirådgivere.`;

export const HIGH_USAGE_TAKEOVER_SUBTEXT = `Hvis størrelsen på varmeforbruget ikke er korrekt, kan du ændre det i forbrugsdata, og få et overslag på en varmepumpe.`;

export const INVALID_HEATING_TEXT = (
  heating: string
) => `Ud fra dine offentlige oplysninger kan vi se, at du har ${heating}. 
Det kan sagtens lade sig gøre at få en${heating === fuelMap['heatPump-air-to-air'] ? ' anden ' : ' '}varmepumpe, 
men det kræver mere information for os at finde den rigtige løsning. 
Vi anbefaler derfor du booker en tid med vores energirådgivere. `;

export const INVALID_HEATING_SUBTEXT = (heating: string) =>
  `Hvis du ikke har ${heating}, kan du ændre din primære varmekilde i forbrugsdata og direkte få et overslag på en varmepumpe.`;

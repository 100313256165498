import assignWith from 'lodash/assignWith';
import round from 'lodash/round';
export const deepNullToUndefined = (object) => {
    if (object === null || object === undefined) {
        return undefined;
    }
    if (isObject(object) || Array.isArray(object)) {
        for (const key in object) {
            object[key] = deepNullToUndefined(object[key]);
        }
    }
    return object;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const roundObjectValuesDeep = (object, decimals = 2) => assignWith(object, object, (value) => {
    if (!value || typeof value === 'string' || typeof value === 'boolean') {
        return value;
    }
    if (typeof value === 'number') {
        return round(value, decimals);
    }
    if (isObject({ ...value }) || Array.isArray(value)) {
        return roundObjectValuesDeep(value, decimals);
    }
});
const isObject = (object) => object?.constructor?.name === 'Object';

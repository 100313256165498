/* eslint-disable @typescript-eslint/no-namespace */
import { CSSProperties } from 'styled-components';

import { BodilColor } from './color';

export namespace BodilFont {
  export type Type = 'default' | 'heading';
  const color = BodilColor.base.default;

  const font: Record<Type, string> = {
    default: 'Graphik',
    heading: 'GalanoGrotesque',
  };

  export type Size = 10 | 12 | 14 | 16 | 18 | 20 | 24 | 32;

  export const style = (type: Type, fontSize: Size): CSSProperties => {
    const fontFamily = font[type];
    switch (type) {
      case 'heading':
        return { fontFamily, fontWeight: 600, fontSize, color };
      case 'default':
      default:
        return { fontFamily, fontSize, transform: `translate(0, ${fontSize / 15}px)`, color };
    }
  };
}

export namespace NorlysFont {
  export type Type = 'default' | 'heading';

  const font: Record<Type, string> = { default: 'NorlysBody', heading: 'NorlysHeading' };

  export type Size = 10 | 12 | 14 | 16 | 18 | 20 | 22 | 26 | 30 | 59;

  export const style = (type: Type, fontSize: Size, color: string = 'black'): CSSProperties => {
    const fontFamily = font[type];

    if (type === 'heading') {
      return { color, fontFamily, fontSize, fontWeight: 'bold', marginBottom: 0 };
    }

    return { color, fontFamily, fontSize };
  };
}

import { HeatSource } from '@src/model';

const ELECTRIC_HEATING_OPTIONS: HeatSource[] = [
  { value: 'electricity', label: 'Elvarmesystem', efficiency: 1 },
  { value: 'heatPump-air-to-air', fuelType: 'electricity', label: 'Luft til luft varmepumpe', efficiency: 4 },
  { value: 'heatPump-air-to-water', fuelType: 'electricity', label: 'Luft til vand varmepumpe', efficiency: 2 },
];

const WOOD_BURNER_OPTIONS = {
  value: 'wood',
  label: 'Pillefyr',
  efficiencyOptions: [
    { value: '0.85', label: `Nyere end 2010` },
    { value: '0.75', label: '2000-2010' },
    { value: '0.65', label: 'Ældre end 2000' },
  ],
};

export const PRIMARY_HEATING_OPTIONS: HeatSource[] = [
  {
    value: 'oil',
    label: 'Oliefyr',
    efficiencyOptions: [
      { value: '0.99', label: 'Under 10 år med kondensator' },
      { value: '0.96', label: 'Over 10 år med kondensator' },
      { value: '0.8', label: 'Uden kondensator' },
      { value: '0.91', label: `Isoleret kedel '70-'00` },
      { value: '0.87', label: `Uisoleret kedel '70-'90` },
      { value: '0.6', label: `Støbejernskedel` },
    ],
  },
  {
    value: 'gas',
    label: 'Gasfyr',
    efficiencyOptions: [
      { value: '0.87', label: `Før '90` },
      { value: '0.94', label: 'Kondensator over 10 år' },
      { value: '0.97', label: 'Kondensator under 10 år' },
    ],
  },
  WOOD_BURNER_OPTIONS,
  {
    value: 'straw',
    label: 'Halmfyr',
    efficiencyOptions: [
      { value: '0.88', label: `Nyt` },
      { value: '0.75', label: 'Gammelt' },
    ],
  },
  {
    value: 'districtHeating',
    label: 'Fjernvarme',
    efficiency: 1,
  },
  ...ELECTRIC_HEATING_OPTIONS,
];

import { PageMargin } from '@src/branding/spacing';
import { BODIL_ENERGI } from '@src/constants';

import { Logo, Navigation, NavigationItem, StyledHeader } from './style';

const links = [
  { title: 'Spørgsmål og Svar', href: `${BODIL_ENERGI}/faq` },
  { title: 'Om Bodil', href: `${BODIL_ENERGI}/om-bodil` },
  { title: 'Blog', href: `${BODIL_ENERGI}/blog` },

  { title: 'Bliv ringet op', href: `https://hej-bodil-energi.youcanbook.me`, uppercase: true },
];

type Props = { margin?: PageMargin };

export const Header = ({ margin }: Props) => {
  return (
    <StyledHeader {...{ margin }}>
      <Logo href='/' src='/logo/bodil-dark.png' />
      <Navigation>
        {links.map(({ title, href, uppercase }) => (
          <NavigationItem style={{ textTransform: uppercase ? 'uppercase' : undefined }} key={title} href={href}>
            {title}
          </NavigationItem>
        ))}
      </Navigation>
    </StyledHeader>
  );
};

import { ReactNode } from 'react';
import styled from 'styled-components';

import { useBranding } from '@src/context/branding';
import { IconProps } from '@src/model';

const Wrapper = styled.div<IconProps>(({ color = 'black', inline, width: inputWidth, height: inputHeight }) => {
  const width = inputWidth ?? (inputHeight ? undefined : '20px');
  const height = inputHeight ?? (inputWidth ? undefined : '20px');

  return {
    svg: { width, height },
    path: { fill: color },
    '.no-fill': { fill: 'unset' },
    display: inline ? 'inline-block' : 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width,
    height,
    aspectRatio: width && height ? undefined : `1 / 1`,
  };
});

export const IconWrapper = ({ color: colorInput, style, children, ...props }: IconProps & { children?: ReactNode }) => {
  const { darkColor, lightColor } = useBranding();
  const color = colorInput === 'dark' ? darkColor : colorInput === 'light' ? lightColor : colorInput;

  return (
    <Wrapper style={style} color={color} {...props}>
      {children}
    </Wrapper>
  );
};

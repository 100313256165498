import { CSSProperties } from 'react';

import { useDisplayContext } from '@src/context/display';

export const useRootStyle = () => {
  const { width } = useDisplayContext();

  const padding = width / 16;

  const fillWidthStyle: CSSProperties = {
    width: `calc(100% + ${padding * 2}px)`,
    marginLeft: -padding,
  };

  return { padding, fillWidthStyle };
};

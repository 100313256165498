/* eslint-disable @typescript-eslint/no-namespace */

export namespace BodilColor {
  export const base = {
    default: '#013433',
    grey: '#646A7C',
    white: '#FFFFFF',
    lavender: '#D7CAFF',
    almond: '#F9DEC5',
    azure: '#D1DDF2',
    smoke: '#F6F7FC',
    breeze: '#CEF2E6',
  };

  export const contrast = {
    almond: '#BCADA3',
    breeze: '#A0B4A1',
  };

  export const greyscale = {
    '00': '#373B47',
    '10': '#646A7C',
    '20': '#9CA2B6',
    '30': '#D9DDE9',
    '40': '#E9EBF2',
  };

  export const ui = {
    action: {
      default: '#01918E',
      '10': '#007673',
      '11': '#005E5C',
      '20': '#DEEBEB',
      '21': '#B6CFCF',
    },
    error: '#FF5F5F',
    success: '#28C28B',
    attention: '#FAC818',
    orange: '#FFA346',
    blue: '#009FDA',
  };
}

export namespace NorlysColor {
  export const primary = {
    darkBlue: '#002B6B',
    acid: '#25D287',
    petrol: '#369993',
    sand: '#F5F5ED',
    red: '#F8232D',
    pink: '#FFDEE5',
  };

  export const secondary = {
    clearBlue: '#0A77ED',
    lightBlue: '#D4EEFA',
    brown: '#694F38',
    lightBrown: '#EEE7E2',
    purple: '#755FFA',
    lightPurple: '#E3E1FF',
    darkGreen: '#103C23',
    mint: '#D3F4E8',
    yellow: '#FFDB38',
  };
}

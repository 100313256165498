export const DEFAULT_RESIDENCE = {
    area: 120,
    areaHeated: 120,
    energyExpenditure: 3600,
    floors: 1,
    heatDistribution: 'radiator',
    primaryHeating: { annualUsage: 0, fuelType: '' },
    renovatedDegree: 'medium',
    residents: 4,
    type: 'villa',
    waterConsumption: 'medium',
    noiseWeight: 'medium',
};

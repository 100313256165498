import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

const INTERVAL = 333;

export const useCookie = (cookieName: string) => {
  const [cookie, setCookie] = useState(Cookies.get(cookieName));

  const removeCookie = () => {
    Cookies.remove(cookieName);
  };

  const updateCookie = (newCookie: string) => {
    Cookies.set(cookieName, newCookie);
  };

  useEffect(function listenForCookieChanges() {
    const cookieChangeListener = setInterval(() => {
      const browserCookie = Cookies.get(cookieName);

      if (cookie !== browserCookie) {
        setCookie(browserCookie);
      }
    }, INTERVAL);

    return () => {
      clearInterval(cookieChangeListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { cookie, removeCookie, updateCookie };
};

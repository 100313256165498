import { FC } from 'react';

import { useBranding } from '@src/context/branding';
import { useDisplayContext } from '@src/context/display';

import { headingStyle, textStyle } from './style';
import { HeadingProps, TextProps } from './types';

type TextColor = 'light' | 'dark';

export * from './style';
export * from './types';

export const Heading: FC<HeadingProps & { color?: TextColor }> = ({
  children,
  level = 1,
  as: Tag = 'div',
  style: inputStyle,
  color: colorInput = 'dark',
  center,
  ...rest
}) => {
  const { isMobile } = useDisplayContext();

  const color = useBrandColors(colorInput);
  const style = { ...headingStyle(level, isMobile, center), color, ...inputStyle };

  return <Tag {...{ style, ...rest }}>{children}</Tag>;
};

export const Text: FC<TextProps & { color?: TextColor }> = ({
  children,
  level = 1,
  as: Tag = 'div',
  type,
  strong,
  center,
  style: inputStyle,
  color: colorInput = 'dark',
  ...rest
}) => {
  const { isMobile } = useDisplayContext();

  const color = useBrandColors(colorInput);
  const style = { ...textStyle(level, isMobile, type, strong, center), color, ...inputStyle };

  return <Tag {...{ style, ...rest }}>{children}</Tag>;
};

const useBrandColors = (color?: TextColor) => {
  const { darkTextColor, lightTextColor } = useBranding();
  return color === 'dark' ? darkTextColor : lightTextColor;
};

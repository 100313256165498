import { ReactNode } from 'react';
import { CSSProperties } from 'styled-components';

type Props = {
  href: string;
  newTab?: boolean;
  style?: CSSProperties;
  children?: ReactNode;
};

export const Anchor = ({ href, newTab, style, children }: Props) => {
  return (
    <a
      {...{
        style,
        href,
        target: newTab ? '_blank' : undefined,
        rel: 'noopener noreferrer',
      }}
    >
      {children}
    </a>
  );
};

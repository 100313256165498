import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import round from 'lodash/round';

import { BodilColor } from '@src/branding';

import { HeadingLevel, TextLevel, TextType } from './types';

export const headingStyle = (level: HeadingLevel, isMobile = false, center?: boolean) => {
  const fontSize = headingSize(level, isMobile);
  const fontWeight = level === 4 ? 400 : 600;

  return {
    fontFamily: 'GalanoGrotesque',
    fontStyle: 'normal',
    color: BodilColor.base.default,
    fontSize,
    fontWeight,
    lineHeight: `${round(1.33 * fontSize)}px`,
    ...emphasisStyle(undefined, undefined, center),
  };
};

const headingSize = (level: HeadingLevel, isMobile = false) => {
  switch (level) {
    case 2:
      return isMobile ? 16 : 18;
    case 3:
      return isMobile ? 12 : 14;
    case 4:
      return isMobile ? 10 : 12;
    default:
    case 1:
      return isMobile ? 20 : 22;
  }
};

export const textStyle = (level: TextLevel, isMobile = false, type?: TextType, strong?: boolean, center?: boolean) => {
  const fontSize = textSize(level, isMobile);
  const lineHeight = level === 2 ? 15 : round(fontSize * 1.33);

  return {
    fontFamily: 'Graphik',
    fontStyle: 'normal',
    fontSize,
    lineHeight: `${lineHeight}px`,
    fontWeight: 400,
    color: BodilColor.base.default,
    ...emphasisStyle(type, strong, center),
  };
};

const textSize = (level: TextLevel, isMobile = false) => {
  switch (level) {
    case 2:
      return isMobile ? 12 : 14;
    default:
    case 1:
      return isMobile ? 12 : 18;
  }
};

export const emphasisStyle = (type?: TextType, strong?: boolean, center?: boolean) =>
  pickBy(
    { color: getTextColor(type), fontWeight: strong ? 600 : undefined, ...(center ? { textAlign: 'center' } : {}) },
    identity
  );

const getTextColor = (type?: TextType) => {
  switch (type) {
    case 'primary':
      return BodilColor.ui.action.default;
    case 'secondary':
      return BodilColor.greyscale[20];
    default:
      return BodilColor.base.default;
  }
};

export const SECOND = 1e3;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;
export const MONTH = 2.628e6 * SECOND;
export const YEAR = 3.154e7 * SECOND;
export const timeToNumber = ({ s, m, h, d, w, M, Y }) => (s ?? 0) * SECOND +
    (m ?? 0) * MINUTE +
    (h ?? 0) * HOUR +
    (d ?? 0) * DAY +
    (w ?? 0) * WEEK +
    (M ?? 0) * MONTH +
    (Y ?? 0) * YEAR;
export const addDate = (t, startDate = new Date(), config = {}) => {
    const timeMS = typeof t === 'number' ? t : timeToNumber(t);
    const targetDate = new Date(startDate.getTime() + timeMS);
    const { excludeWeekends = false } = config;
    if (excludeWeekends) {
        const weekendDays = findWeekendDaysInRange(startDate, targetDate);
        return weekendDays === 0 ? targetDate : addDate({ d: weekendDays }, targetDate, { excludeWeekends: false });
    }
    return targetDate;
};
export const findWeekendDaysInRange = (date1, date2) => {
    if (date1.getTime() > date2.getTime()) {
        return findWeekendDaysInRange(date2, date1);
    }
    let count = 0;
    const current = new Date(date1.getTime());
    while (current <= date2) {
        const dayOfWeek = current.getDay();
        if (dayOfWeek === 0 || dayOfWeek === 6) {
            count++;
        }
        current.setDate(current.getDate() + 1);
    }
    return count;
};
const zeroPad = (n) => `${n < 10 ? '0' : ''}${n}`;
export const formatDate = (time, includeTimestamp = true, timezoneOffset = new Date(time).getTimezoneOffset() / 60) => {
    const date = new Date(time);
    const day = date.getUTCDate();
    const month = date.getUTCMonth();
    const year = date.getFullYear();
    const hours = date.getUTCHours() - timezoneOffset;
    const minutes = date.getUTCMinutes();
    const timestamp = includeTimestamp ? ` ${zeroPad(hours)}:${zeroPad(minutes)}` : '';
    return `${zeroPad(day)}/${zeroPad(month + 1)}/${year}${timestamp}`;
};
export const formatTime = (time, timezoneOffset = new Date(time).getTimezoneOffset() / 60) => {
    const date = new Date(time);
    const hours = date.getUTCHours() - timezoneOffset;
    const minutes = date.getUTCMinutes();
    return `${zeroPad(hours)}:${zeroPad(minutes)}`;
};
export const isLeapYear = (year) => {
    if (year % 400 === 0) {
        return true;
    }
    if (year % 100 === 0) {
        return false;
    }
    return year % 4 === 0;
};

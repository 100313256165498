type Environment = 'development' | 'staging' | 'production';

export const getEnvironment = (): Environment => {
  const { href } = globalThis?.location ?? {};

  if (href?.includes('localhost')) {
    return 'development';
  }

  if (href?.includes('staging')) {
    return 'staging';
  }

  return 'production';
};

/* eslint-disable camelcase */

import { useEffect } from 'react';

type WindowWithTracking = typeof window & {
  gtag?: (...args: unknown[]) => void;
};

type GtagConsentParameters = {
  adStorage?: boolean;
  adUserData?: boolean;
  adPersonalization?: boolean;
  analyticsStorage?: boolean;
};

export const useGtagConsent = (parameters: GtagConsentParameters) => {
  useEffect(
    function updateGtagConsent() {
      const { gtag } = (window as WindowWithTracking) ?? {};

      const consent = gtagParamertersToConsent(parameters);
      const isDefaultState = Object.values(consent).every(value => value === 'denied');

      gtag?.('consent', isDefaultState ? 'default' : 'update', consent);
    },
    [parameters]
  );
};

const gtagParamertersToConsent = (parameters: GtagConsentParameters) => ({
  ad_storage: evaluateConsent(parameters.adStorage),
  ad_user_data: evaluateConsent(parameters.adUserData),
  ad_personalization: evaluateConsent(parameters.adPersonalization),
  analytics_storage: evaluateConsent(parameters.analyticsStorage),
});

const evaluateConsent = (value?: boolean) => (value ? 'granted' : 'denied');

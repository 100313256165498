export const CALCULATOR_THANK_YOU_PAGE = 'https://bodil.energy/din-rapport-er-pa-vej/';
export const CALCULATOR_THANK_YOU_PAGE_NORLYS = 'https://partner.norlys.dk/BodilEnergi/';

export const CREATE_OFFER_ERROR_MESSAGE = `Der skete desværre en fejl, og Deres tilbud kunne ikke oprettes.\n\nPrøv igen, eller kontakt os direkte på mail eller telefon.\n\nVi beklager ulejligheden.`;

export const EDC_BOOK_URL = 'https://bodil.energy/edc-energiradgivning-booking';
export const BODIL_BOOK_URL = 'https://bodil.energy/book-et-kald/';
export const NORLYS_BOOK_URL = 'https://bodil.energy/norlys-booking/';

export const BODIL_ENERGI = 'https://bodil.energy';
export const BODIL_EMAIL = 'hej@bodil.energy';
export const BODIL_PHONE_NUMBER = '+ 45 71 74 18 41';
export const BODIL_ADDRESS = 'Frederiksborggade 22, 1360 København K';
export const BODIL_CVR = '41246421';
export const BODIL_COPYRIGHT = '©2024 Bodil Energi';

import styled from 'styled-components';

import { BodilColor, BodilFont } from '@src/branding';
import { boxShadows } from '@src/branding/boxShadow';
import { HEADER_HEIGHT } from '@src/branding/constants';
import { defaultSideMargin, PageMargin } from '@src/branding/spacing';

export const StyledHeader = styled.div<{ margin?: PageMargin }>(({ margin = 'both' }) => ({
  width: '100%',
  position: 'fixed',
  lineHeight: '23px',
  fontWeight: 500,
  left: 0,
  top: 0,
  display: 'flex',
  flexFlow: 'row wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  ...boxShadows.large,
  ...BodilFont.style('heading', 18),
  ...defaultSideMargin(margin),
  background: BodilColor.base.white,
  zIndex: 1000,
  height: `${HEADER_HEIGHT}px`,
}));

export const Navigation = styled.div({
  fontSize: '14px',
  ...BodilFont.style('heading', 16),
  letterSpacing: '-.9px',
  wordSpacing: 0.5,
  fontWeight: 450,
  display: 'flex',
  justifyContent: 'space-between',
});

export const NavigationItem = styled.a({
  paddingRight: '32px',
});

export const Logo = styled.a<{ src: string }>(({ src }) => ({
  height: '30px',
  width: '95px',
  backgroundImage: `url('${src}')`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  margin: 'auto 32px auto 0',
}));

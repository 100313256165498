import { useEnvironment } from '@src/hooks/useEnvironment';
import { Script } from '@src/model';

import { useCookieConsent } from './useCookieConsent';
import { useGtagConsent } from './useGtagConsent';

const ENERGYHOME_ADVERTISEMENT = [{ defer: true, src: '/scripts/meta-pixel.js' }];

const GTAG_SCRIPTS = [
  { defer: true, src: `/scripts/gtag-calculator-analytics.js` },
  { defer: true, src: `/scripts/gtag-ads.js` },
];

const ENERGYHOME_ANALYTICS = [
  { defer: true, src: '/scripts/clarity.js' },
  { defer: true, src: '/scripts/gclid.js' },
  { defer: true, src: '/scripts/gtm.js' },
  { defer: true, src: '/scripts/utm.js' },
];

const GTAG_SCRIPTS_STAGING = [{ defer: true, src: `/scripts/gtag-calculator-analytics-staging.js` }];

const ENERGYHOME_ANALYTICS_STAGING = [{ defer: true, src: '/scripts/gclid.js' }];

export const useTrackingScripts = (): Script[] => {
  const { isDebug, isProduction, isLocalhost } = useEnvironment();

  const { isAdvertisementAllowed, isAnalyticsAllowed } = useCookieConsent();

  useGtagConsent({
    adStorage: isAdvertisementAllowed,
    adPersonalization: isAdvertisementAllowed,
    analyticsStorage: isAnalyticsAllowed,
    adUserData: isAdvertisementAllowed,
  });

  if (isDebug || isLocalhost) {
    return [];
  }

  const trackingScripts: Script[] = isProduction ? GTAG_SCRIPTS : GTAG_SCRIPTS_STAGING;

  if (isAnalyticsAllowed) {
    const analyticsScripts = isProduction ? ENERGYHOME_ANALYTICS : ENERGYHOME_ANALYTICS_STAGING;
    trackingScripts.push(...analyticsScripts);
  }

  if (isAdvertisementAllowed && isProduction) {
    trackingScripts.push(...ENERGYHOME_ADVERTISEMENT);
  }

  return trackingScripts;
};

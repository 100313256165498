import { Script } from '@src/model';

import { useEnvironment } from './useEnvironment';

export const useCookieScripts = (): Script[] => {
  const { isLocalhost } = useEnvironment();

  if (isLocalhost) {
    return [];
  }

  return [
    {
      id: 'cookieyes',
      defer: true,
      src: 'https://cdn-cookieyes.com/client_data/74ccc4d9b597d05ce0a6b8bd/script.js',
    },
  ];
};

import { HeatPumpIndoorUnit, HeatPumpOutdoorUnit } from '@src/model';

const recommended: Partial<Record<HeatPumpOutdoorUnit['brand'], number>> = {
  Mitsubishi: 17,
};

export const findBestCompatibleIndoorUnit = <
  HPO extends Pick<HeatPumpOutdoorUnit, 'compatibleIndoorUnits' | 'brand'>,
  HPI extends Pick<HeatPumpIndoorUnit, 'id' | 'price'>,
>(
  outdoorUnit?: HPO,
  indoorUnits?: HPI[],
  reccomendations = recommended
) => {
  if (!outdoorUnit || !indoorUnits?.length) {
    return undefined;
  }

  const ids = outdoorUnit?.compatibleIndoorUnits;
  if (!ids?.length) {
    return undefined;
  }

  const recommendedID = reccomendations[outdoorUnit.brand];
  const recommendedUnit = recommendedID ? indoorUnits.find(({ id }) => id === recommendedID) : undefined;

  const relevantUnits = indoorUnits.filter(({ id }) => ids.includes(id));

  return (
    recommendedUnit ??
    relevantUnits?.reduce(
      (unit, iterated) => (ids?.includes(iterated.id) && iterated.price < (unit?.price ?? 0) ? iterated : unit),
      relevantUnits[0]
    )
  );
};

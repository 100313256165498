import styled from 'styled-components';

import { BodilColor, NorlysFont } from '@src/branding';
import { DESKTOP_FOOTER_HEIGHT, MOBILE_FOOTER_HEIGHT } from '@src/branding/constants';

export const Footer = styled.footer<{ isMobile?: boolean }>(({ isMobile = false }) => ({
  color: isMobile ? BodilColor.ui.action.default : 'black',
  height: isMobile ? MOBILE_FOOTER_HEIGHT : DESKTOP_FOOTER_HEIGHT,
  padding: isMobile ? '8px' : '24px 64px 16px',
  backgroundColor: isMobile ? 'transparent' : 'white',
  width: '100%',
}));

export const FooterNorlys = styled.footer<{ isMobile?: boolean }>(({ isMobile = false }) => ({
  color: 'black',
  height: isMobile ? MOBILE_FOOTER_HEIGHT : DESKTOP_FOOTER_HEIGHT,
  padding: isMobile ? '8px' : '24px 64px 16px',
  backgroundColor: isMobile ? 'white' : 'transparent',
  width: '100%',
}));

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export const Wrapper = styled.div({
  padding: '16px 8px',
  display: 'flex',
  justifyContent: 'space-between',
});

export const Image = styled.img({
  marginTop: '8px',
  padding: '4px',
});

export const Icons = styled.div({
  margin: '4px',
  padding: '4px',
  display: 'flex',
});

export const TextNorlys = styled.div<{ fontType?: NorlysFont.Type; fontSize?: NorlysFont.Size; color?: string }>(
  ({ fontType = 'default', fontSize = 16, color }) => ({
    display: 'flex',
    alignItems: 'center',
    margin: '4px',
    padding: '4px',
    ...NorlysFont.style(fontType, fontSize),
    color: color ?? 'inherit',
  })
);

import { createContext, useContext } from 'react';

import { useEnvironment } from '@src/hooks/useEnvironment';

export type EnvironmentContext = ReturnType<typeof useEnvironment>;

const environmentContext = createContext<EnvironmentContext>({
  isDebug: false,
  isLocalhost: false,
  isProduction: false,
  isStaging: false,
});

export const EnvironmentContextProvider = environmentContext.Provider;

export const useEnvironmentContext = () => useContext<EnvironmentContext>(environmentContext);

export const DEGREE_DAYS = 2834;
export const DEGREE_DAY_RATIO = 0.75;
export const DEGREE_DAYS_WEIGTED = DEGREE_DAYS * DEGREE_DAY_RATIO;
export const MINUS_12_TO_MINUS_7_RATIO = 0.84375;
export const PRICE_ELECTRICITY = 2.8;
export const ELECTRICITY_DISCOUNT = 1.1;
export const ELECTRICITY_MINIMUM_SELL_PRICE = 0.77;
export const PRICE_ELECTRICITY_DISCOUNT = PRICE_ELECTRICITY - ELECTRICITY_DISCOUNT;
export const SELL_PRICE_ELECTRICITY_REDUCTION = 1.7;
export const DISCOUNT_ELECTRICITY_REQUIRED = 4000;

import { useQuery } from 'urql';

import { EnergyOptionsDocument } from '@src/graphql';
import { deepNullToUndefined, mapConstantsToEnergyOptions } from '@src/util';

import { useQueryToaster } from './useGraphQLToaster';

export const useFetchEnergyOptions = () => {
  const [result] = useQuery({ query: EnergyOptionsDocument });

  useQueryToaster(result, {
    error: { header: 'Serverfejl', message: 'Kunne ikke hente energiværdier' },
  });

  const { energyOptions } = result.data ?? {};

  return mapConstantsToEnergyOptions(deepNullToUndefined(energyOptions) ?? []);
};

import noop from 'lodash/noop';

type LocalStoreKey =
  | 'accessToken'
  | 'debug'
  | 'financingProvider'
  | 'gclid'
  | 'leadOrigin'
  | 'loginRedirectUrl'
  | 'postLoginURI'
  | 'refreshToken'
  | 'userEmail';

export const localStore = () => {
  return typeof window === 'undefined'
    ? {
        set: noop,
        get: () => '',
        clear: noop,
        clearAll: noop,
      }
    : {
        set: (key: LocalStoreKey, data?: string) => (data ? localStorage.setItem(key, data) : undefined),
        get: (key: LocalStoreKey) => localStorage.getItem(key),
        clear: (key: LocalStoreKey) => localStorage.removeItem(key),
        clearAll: () => localStorage.clear(),
      };
};

export const sessionStore = () => {
  return typeof window === 'undefined'
    ? {
        set: noop,
        get: () => '',
        clear: noop,
        clearAll: noop,
      }
    : {
        set: (key: string, data?: unknown) => (data ? sessionStorage.setItem(key, JSON.stringify(data)) : undefined),
        get: (key: string) => JSON.parse(sessionStorage.getItem(key) ?? '{}'),
        clear: (key: string) => sessionStorage.removeItem(key),
        clearAll: () => sessionStorage.clear(),
      };
};

import { PageProps } from 'gatsby';
import omit from 'lodash/omit';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Client, Provider as GraphQLProvider } from 'urql';

import { BrandingContextProvider } from '@src/context/branding';
import { CustomerContextProvider, CustomerWithExtraData } from '@src/context/customerContext';
import { DisplayContextProvider } from '@src/context/display';
import { EnergyOptionsContextProvider } from '@src/context/energyOptions';
import { EnvironmentContextProvider } from '@src/context/environment';
import { GraphQLClientProvider } from '@src/context/graphqlClient';
import { LocationContextProvider } from '@src/context/location';
import { ModalContextProvider, Modals, showNextModal } from '@src/context/modal';
import { ToasterContextProvider } from '@src/context/toaster';
import { createGraphQLClient } from '@src/graphql';
import { useFetchEnergyOptions } from '@src/hooks/graphql/useConstant';
import { useCookieScripts } from '@src/hooks/useCookieScripts';
import { useCustomerChatScripts } from '@src/hooks/useCustomerChatScripts';
import { useDisplay } from '@src/hooks/useDisplay';
import { useEnvironment } from '@src/hooks/useEnvironment';
import { useFinancingProvider } from '@src/hooks/useFinancingProvider';
import { useTrackingScripts } from '@src/tracking/useTrackingScripts';

import { enableSentry } from '../sentry';
import { ErrorBoundary } from './ErrorBoundary';

import '@src/branding/base/base.css';
import '@src/branding/base/font.css';

enableSentry();

const ModalProvider = ({ children }: { children?: ReactNode }) => {
  const [modals, setModals] = useState<Modals>({});

  const trackingScripts = useTrackingScripts();
  const chatScripts = useCustomerChatScripts();
  const cookieScripts = useCookieScripts();

  const scripts = useMemo(
    () => [...trackingScripts, ...chatScripts, ...cookieScripts],
    [trackingScripts, chatScripts, cookieScripts]
  );

  useEffect(function updateViewPortMetaTag() {
    if (navigator.userAgent.includes('iPhone')) {
      document
        ?.querySelector('meta[name="viewport"]')
        ?.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1, shrink-to-fit=no');
    }
  }, []);

  return (
    <ModalContextProvider
      value={{
        showModal: (modal, key) => {
          setTimeout(() => {
            const entry = { modal, order: Object.keys(modals).length };
            setModals({ [key]: entry, ...modals });
          });
        },
        hideModal: key => setModals(omit(modals, key)),
      }}
    >
      <Helmet bodyAttributes={{ class: Object.keys(modals).length > 0 ? 'no-scroll' : undefined }}>
        <title>Bodil Energiberegner</title>

        <link rel='icon' type='image/png' href='/icons/favicon.ico' sizes='16x16' />

        {scripts.map(({ async, defer, src, id }) => (
          <script async={async} defer={defer} id={id} key={src} src={src} type='text/javascript' />
        ))}
      </Helmet>

      {children}

      {showNextModal(modals)}
    </ModalContextProvider>
  );
};

export const pageWrapper = ({ element, props }: { element: ReactNode; props: PageProps }) => {
  const { location } = props;

  return (
    <ErrorBoundary>
      <ToasterContextProvider>
        <GraphQLWrapper>
          <LocationContextProvider value={{ location, props }}>
            <DisplayTypeWrapper>
              <EnvironmentWrapper>
                <OrganisationBrandingWrapper>
                  <CustomerContext>
                    <ModalProvider>
                      <EnergyOptionsWrapper>{element}</EnergyOptionsWrapper>
                    </ModalProvider>
                  </CustomerContext>
                </OrganisationBrandingWrapper>
              </EnvironmentWrapper>
            </DisplayTypeWrapper>
          </LocationContextProvider>
        </GraphQLWrapper>
      </ToasterContextProvider>
    </ErrorBoundary>
  );
};

type ChildrenProps = { children?: ReactNode };

const GraphQLWrapper = ({ children }: ChildrenProps) => {
  const [client, setClient] = useState<Client>(createGraphQLClient());

  return (
    <GraphQLClientProvider value={{ resetClient: () => setClient(createGraphQLClient()) }}>
      <GraphQLProvider value={client}>{children}</GraphQLProvider>
    </GraphQLClientProvider>
  );
};

const EnergyOptionsWrapper = ({ children }: ChildrenProps) => {
  const options = useFetchEnergyOptions();

  return <EnergyOptionsContextProvider value={{ options }}>{children}</EnergyOptionsContextProvider>;
};

const DisplayTypeWrapper = ({ children }: ChildrenProps) => {
  const displayValues = useDisplay();

  return <DisplayContextProvider {...{ value: displayValues }}>{children}</DisplayContextProvider>;
};

const EnvironmentWrapper = ({ children }: ChildrenProps) => {
  const environmentValues = useEnvironment();

  return <EnvironmentContextProvider {...{ value: environmentValues }}>{children}</EnvironmentContextProvider>;
};

const OrganisationBrandingWrapper = ({ children }: ChildrenProps) => {
  useFinancingProvider();
  return <BrandingContextProvider value={{}}>{children}</BrandingContextProvider>;
};

const CustomerContext = ({ children }: ChildrenProps) => {
  const [customer, setCustomer] = useState<Partial<CustomerWithExtraData>>();

  return <CustomerContextProvider value={{ customer, setCustomer }}>{children}</CustomerContextProvider>;
};

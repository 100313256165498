import { BodilColor, NorlysFont } from '@src/branding';
import { Anchor } from '@src/components/Anchor';
import { Icon } from '@src/components/Icon';
import { Heading, Text } from '@src/components/Typography';
import { BODIL_ADDRESS, BODIL_COPYRIGHT, BODIL_CVR, BODIL_EMAIL, BODIL_PHONE_NUMBER } from '@src/constants';
import { useDisplayContext } from '@src/context/display';

import { Container, Footer, FooterNorlys, Icons, Image, TextNorlys, Wrapper } from './style';

export const CalculatorFooter = () => <CalculatorFooterBodil />;

const CalculatorFooterBodil = () => {
  const { isMobile } = useDisplayContext();

  const textStyle = isMobile
    ? { color: BodilColor.base.grey, margin: '0 32px', paddingTop: 0 }
    : { color: BodilColor.greyscale['00'] };

  const iconProps = {
    color: isMobile ? 'light' : 'dark',
    style: { marginRight: '8px' },
  };

  return (
    <Footer isMobile={isMobile}>
      <Wrapper>
        <Container>
          <a href={`mailto:${BODIL_EMAIL}`}>
            <Heading level={2} style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
              <Icon.Email {...iconProps} /> {BODIL_EMAIL}
            </Heading>
          </a>
          <a href={`tel:${BODIL_PHONE_NUMBER}`}>
            <Heading level={2} style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
              <Icon.Phone {...iconProps} /> {BODIL_PHONE_NUMBER}
            </Heading>
          </a>
          <Text level={2} style={textStyle}>
            09.00 - 17.00 alle hverdage
          </Text>
          {!isMobile && (
            <Icons>
              <Anchor href='https://www.facebook.com/pg/bodilenergi/' newTab>
                <Icon.Facebook {...{ ...iconProps, width: '40px', height: '40px' }} />
              </Anchor>
              <Anchor
                href='https://www.linkedin.com/company/bodil/'
                newTab
                style={{ marginLeft: '8px', marginTop: '4px' }}
              >
                <Icon.LinkedIn {...{ ...iconProps, width: '40px', height: 35 }} />
              </Anchor>
            </Icons>
          )}
        </Container>
        {!isMobile && (
          <Container
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: isMobile ? 'flex-start' : 'flex-end',
            }}
          >
            <Heading level={3}>
              <Anchor href='https://bodil.energy/privatlivspolitik/' newTab>
                Persondatapolitik
              </Anchor>
            </Heading>
            <Heading level={3}>
              <Anchor href='https://bodil.energy/privatlivspolitik/' newTab>
                Privatlivspolitik
              </Anchor>
            </Heading>
            <Image src={`/images/BODIL_Medlem_TEKNIQ.png`} width='150px' />
            <Image src={`/images/VE_Godkendt.png`} width='38px' />
          </Container>
        )}
      </Wrapper>
      {!isMobile && (
        <>
          <div style={{ padding: '32px 0 0' }}>
            <hr />
          </div>

          <Container style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Text level={2}>{BODIL_COPYRIGHT}</Text>
            <Text level={2}>CVR {BODIL_CVR}</Text>
            <Text level={2}>{BODIL_ADDRESS}</Text>
          </Container>
        </>
      )}
    </Footer>
  );
};

export const CalculatorFooterNorlys = () => {
  const { isMobile } = useDisplayContext();

  const iconProps = {
    style: { marginRight: '8px' },
  };

  const textProps = {
    fontSize: (isMobile ? 10 : 14) as NorlysFont.Size,
    fontType: 'default' as NorlysFont.Type,
    style: { margin: '0 32px', paddingTop: 0 },
  };

  return (
    <FooterNorlys isMobile={isMobile}>
      <Wrapper>
        <Container>
          <a href={`mailto:${BODIL_EMAIL}`}>
            <TextNorlys fontSize={isMobile ? undefined : 16}>
              <Icon.Email {...iconProps} /> {BODIL_EMAIL}
            </TextNorlys>
          </a>
          <a href={`tel:${BODIL_PHONE_NUMBER}`}>
            <TextNorlys fontSize={isMobile ? undefined : 16} style={{ marginBottom: 0 }}>
              <Icon.Phone {...iconProps} /> {BODIL_PHONE_NUMBER}
            </TextNorlys>
          </a>
          <TextNorlys {...textProps}>09.00 - 17.00 alle hverdage</TextNorlys>
        </Container>
      </Wrapper>
    </FooterNorlys>
  );
};

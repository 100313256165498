import mean from 'lodash/mean';
import sumBy from 'lodash/sumBy';
import { filterFalsy } from '@bodilenergy/util';
import { calculateAverageFuelPrices, increaseFuelPrices } from '../lib/constant';
import { calculateAnnualHeatingPrice, calculateUsageForHeatPumpProducts, calculateUsageForResidenceWithoutProducts, calculateUsageForSolarProducts, calculateUsageSolarAndHeatPump, } from './consumption';
import { calculateElectricityPrice } from './electricity';
export const accumulateProductPrices = (products) => sumBy(products, product => (product?.price ?? 0) * (product?.quantity ?? 1));
export const accumulateProductBuyPrices = (products) => sumBy(products, product => (product?.buyPrice ?? 0) * (product?.quantity ?? 1));
export const calculateOfferPrice = (offer) => {
    if (!offer) {
        return 0;
    }
    const products = getOfferProducts(offer);
    return accumulateProductPrices(products);
};
export const calculateOfferBuyPrice = (offer) => {
    if (!offer) {
        return 0;
    }
    const products = getOfferProducts(offer);
    return accumulateProductBuyPrices(products);
};
const getOfferProducts = (offer) => {
    if (!offer) {
        return [];
    }
    const { heatPumpIndoorUnit, heatPumpOutdoorUnit, solarPanel, inverter, battery, expenses, assortedProducts } = offer;
    const products = filterFalsy([heatPumpIndoorUnit, heatPumpOutdoorUnit, solarPanel, inverter, battery]);
    return filterFalsy([...products, ...(expenses ?? []), ...(assortedProducts ?? [])]);
};
export const projectHeatPumpOfferSavings = (parameters) => {
    const usageBefore = calculateUsageForResidenceWithoutProducts(parameters);
    const usageAfter = calculateUsageForHeatPumpProducts(parameters);
    return formatProjectedSavings(usageBefore, usageAfter, parameters.energyOptions);
};
export const projectSolarOfferSavings = (parameters) => {
    const usageBefore = calculateUsageForResidenceWithoutProducts(parameters);
    const usageAfter = calculateUsageForSolarProducts(parameters);
    return formatProjectedSavings(usageBefore, usageAfter, parameters.energyOptions);
};
export const projectOfferSavingsSolarAndHeatPump = (parameters) => {
    const usageBefore = calculateUsageForResidenceWithoutProducts(parameters);
    const usageAfter = calculateUsageSolarAndHeatPump(parameters);
    return formatProjectedSavings(usageBefore, usageAfter, parameters.energyOptions);
};
const formatProjectedSavings = (usageBefore, usageAfter, energyOptions) => {
    const { inflation } = energyOptions;
    return {
        usageBefore,
        usageAfter,
        projectSavings: (years = inflation.years, rate = inflation.rate) => {
            const energyOptionsWithUpdatedInflation = { ...energyOptions, inflation: { years, rate } };
            return projectSavings(usageBefore, usageAfter, energyOptionsWithUpdatedInflation);
        },
        averageSavings: projectSavings(usageBefore, usageAfter, energyOptions).average,
        averagePriceBefore: calculateUsagePrice(usageBefore, calculateAverageFuelPrices(energyOptions)),
        averagePriceAfter: calculateUsagePrice(usageAfter, calculateAverageFuelPrices(energyOptions)),
    };
};
export const calculateSavedPerYear = (before, after) => {
    const savedPerYear = Math.max(before - after, 0);
    const savedPerMonth = savedPerYear / 12;
    const savedPerYearPercentage = savedPerYear > 0 ? (after - before) / before : 0;
    return {
        savedPerYear,
        savedPerMonth,
        savedPerYearPercentage,
    };
};
const projectSavings = (before, after, energyOptions) => {
    const { years, rate } = energyOptions.inflation;
    const finalEnergyOptions = years > 0 && rate > 0 ? increaseFuelPrices(energyOptions) : energyOptions;
    const start = calculateProductSavings(before, after, energyOptions);
    const final = calculateProductSavings(before, after, finalEnergyOptions);
    return {
        start,
        final,
        average: {
            netSpentElectricity: mean([start.netSpentElectricity, final.netSpentElectricity]),
            electricityDiscount: mean([start.electricityDiscount, final.electricityDiscount]),
            boughtPrice: mean([start.boughtPrice, final.boughtPrice]),
            fuelPrice: mean([start.fuelPrice, final.fuelPrice]),
            totalPrice: mean([start.totalPrice, final.totalPrice]),
            totalEmission: mean([start.totalEmission, final.totalEmission]),
            domesticPrice: mean([start.domesticPrice, final.domesticPrice]),
            heatingPrice: mean([start.heatingPrice, final.heatingPrice]),
        },
    };
};
export const calculateProductSavings = (before, after, energyOptions) => {
    const pricesBefore = calculateUsagePrice(before, energyOptions);
    const pricesAfter = calculateUsagePrice(after, energyOptions);
    return {
        boughtPrice: pricesBefore.boughtPrice - pricesAfter.boughtPrice,
        netSpentElectricity: pricesBefore.netSpentElectricity - pricesAfter.netSpentElectricity,
        totalPrice: pricesBefore.totalPrice - pricesAfter.totalPrice,
        electricityDiscount: pricesBefore.electricityDiscount - pricesAfter.electricityDiscount,
        fuelPrice: pricesBefore.fuelPrice - pricesAfter.fuelPrice,
        totalEmission: (before.totalEmission ?? 0) - (after.totalEmission ?? 0),
        domesticPrice: (pricesBefore.domesticPrice ?? 0) - (pricesAfter.domesticPrice ?? 0),
        heatingPrice: (pricesBefore.heatingPrice ?? 0) + (pricesBefore.fuelPrice ?? 0) - (pricesAfter.heatingPrice ?? 0),
    };
};
export const calculateUsagePrice = (usage, energyOptions) => {
    const { boughtDomesticElectricity, boughtHeatingElectricity, electricCarSubsidy = 0, heatings = [], hasElectricityDiscount, soldElectricity, } = usage;
    const { electricity } = energyOptions;
    const { totalPrice: totalElectricityPrice, totalDomesticPrice, totalHeatingPrice, domesticDiscount, heatingDiscount, } = calculateElectricityPrice(boughtDomesticElectricity, boughtHeatingElectricity, energyOptions, hasElectricityDiscount);
    const { fuelPrice } = calculateAnnualHeatingPrice(heatings, energyOptions);
    const soldPrice = soldElectricity * (electricity?.sellPrice ?? 0);
    const netSpentElectricity = totalElectricityPrice - soldPrice - electricCarSubsidy;
    return {
        boughtPrice: totalElectricityPrice,
        netSpentElectricity,
        domesticPrice: totalDomesticPrice,
        heatingPrice: totalHeatingPrice,
        totalPrice: netSpentElectricity + fuelPrice,
        electricityDiscount: domesticDiscount + heatingDiscount,
        fuelPrice,
        soldPrice,
        domesticDiscount,
        heatingDiscount,
    };
};

import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import { convertRate, RateInterval, xirr } from 'node-irr';
import { DAY, isLeapYear, roundToNearest, YEAR } from "../../../util";
export const findJyskeBankLoanCharacteristics = (loanedAmount, loan) => {
    const { annualInstalments, commission, commissionMax, fee, landRegistryFee, landRegistryVariable, startDate = new Date(), } = loan;
    const totalCommission = Math.min(commissionMax ?? Number.MAX_SAFE_INTEGER, loanedAmount * commission);
    const landRegistrySubTotal = (landRegistryFee ?? 0) + (landRegistryVariable ?? 0) * loanedAmount;
    const totalFinancingCost = fee + landRegistrySubTotal + totalCommission;
    const total = roundToNearest(loanedAmount + totalFinancingCost, 1, 'up');
    const instalments = extrapolateLoanInstalments(total, loan);
    const paymentPerMonth = (instalments[0].payment * annualInstalments) / 12;
    const instalmentAmounts = instalments.map(({ payment, date }) => ({ amount: payment, date }));
    const { rate: dailyDebitInterest } = xirr([{ amount: -total, date: startDate }, ...instalmentAmounts]);
    return {
        total,
        instalments,
        instalmentsByYear: aggregateInstalmentsByYear(instalments),
        debitInterest: convertRate(dailyDebitInterest, RateInterval.Year),
        annualExpenseRatio: findLoanAnnualExpenseRatio(total, totalFinancingCost, startDate, instalments),
        paymentPerInstalment: instalments[0].payment,
        paymentPerMonth,
        startDate,
        totalPaid: sumBy(instalments, 'payment'),
        totalFinancingCost,
        totalInterest: sumBy(instalments, 'interest'),
        totalCommission,
        financingCommission: commission,
        financingFee: fee,
        financingLandRegistry: landRegistrySubTotal,
    };
};
export const findLoanCharacteristics = (loanedAmount, loan) => {
    const { annualInstalments, commission, commissionMax, fee, landRegistryFee, landRegistryVariable, startDate = new Date(), } = loan;
    const loanWithFee = loanedAmount ? loanedAmount + fee : 0;
    const commissionBase = loanWithFee * commission;
    const totalCommission = Math.min(commissionMax ?? Number.MAX_SAFE_INTEGER, commissionBase);
    const subtotal = loanWithFee + totalCommission + (landRegistryFee ?? 0);
    const landRegistrySubtotal = (landRegistryVariable ?? 0) * subtotal;
    const total = roundToNearest(subtotal + landRegistrySubtotal, 1000, 'up');
    const totalFinancingCost = subtotal + landRegistrySubtotal - loanedAmount;
    const instalments = extrapolateLoanInstalments(total, loan);
    const [firstInstalment] = instalments;
    const paymentPerMonth = (firstInstalment.payment * annualInstalments) / 12;
    const instalmentAmounts = instalments.map(({ payment, date }) => ({ amount: payment, date }));
    const { rate: dailyDebitInterest } = xirr([{ amount: -total, date: startDate }, ...instalmentAmounts]);
    return {
        total,
        instalments,
        instalmentsByYear: aggregateInstalmentsByYear(instalments),
        debitInterest: convertRate(dailyDebitInterest, RateInterval.Year),
        annualExpenseRatio: findLoanAnnualExpenseRatio(total, totalFinancingCost, startDate, instalments),
        paymentPerInstalment: firstInstalment.payment,
        paymentPerMonth,
        startDate,
        totalPaid: sumBy(instalments, 'payment'),
        totalFinancingCost,
        totalInterest: sumBy(instalments, 'interest'),
        totalCommission,
        financingCommission: commission,
        financingFee: fee,
        financingLandRegistry: landRegistrySubtotal + (landRegistryFee ?? 0),
    };
};
export const findLoanAnnualExpenseRatio = (total, totalFinancingCost, startDate, instalments) => {
    const instalmentAmounts = instalments.map(({ payment, date }) => ({ amount: payment, date }));
    const { rate: dailyTotalExpenses } = xirr([
        { amount: totalFinancingCost - total, date: startDate },
        ...instalmentAmounts,
    ]);
    return convertRate(dailyTotalExpenses, RateInterval.Year);
};
export const extrapolateLoanInstalments = (total, loan) => {
    const { annualInterest, totalYears, annualInstalments, startDate = new Date() } = loan;
    const paymentPerInstalment = findPaymentPerInstalment(total, annualInterest, totalYears, annualInstalments);
    const totalInstalments = totalYears * annualInstalments;
    return Array.from({ length: totalInstalments }).reduce((instalments, _, index) => {
        const { remaining, date } = instalments[index - 1] ?? { remaining: total };
        const entry = calculateLoanInstalment(remaining, paymentPerInstalment, loan, date ?? startDate, index === totalInstalments - 1);
        return [...instalments, entry];
    }, []);
};
export const findPaymentPerInstalment = (total, annualInterest, totalYears, annualInstalments) => {
    const interestPerInstalment = annualInterest / annualInstalments;
    const numerator = total * interestPerInstalment;
    const denominator = 1 - Math.pow(1 + interestPerInstalment, -totalYears * annualInstalments);
    return numerator / denominator;
};
const calculateLoanInstalment = (remaining, paymentPerInstalment, loan, previousDate, isLastPayment = false) => {
    const { annualInterest, annualInstalments } = loan;
    const payment = Math.min(remaining, paymentPerInstalment);
    const date = nextInstalmentDate(previousDate, annualInstalments);
    const daysThisYear = isLeapYear(previousDate.getFullYear()) ? 366 : 365;
    const daysElapsed = Math.floor(date.getTime() - previousDate.getTime()) / DAY;
    const interest = annualInterest * remaining * (daysElapsed / daysThisYear);
    const decrease = isLastPayment ? payment : payment - interest;
    return {
        payment: isLastPayment ? payment + interest : payment,
        interest,
        decrease,
        remaining: isLastPayment ? 0 : remaining - decrease,
        date,
    };
};
const nextInstalmentDate = (previousDate, annualInstalments) => {
    if (12 % annualInstalments !== 0) {
        // instalments not easy to distribute in a year
        return new Date(previousDate.getTime() + YEAR / annualInstalments);
    }
    const previousDateMonth = previousDate.getUTCMonth();
    const instalmentDate = new Date(previousDate);
    instalmentDate.setUTCDate(1);
    instalmentDate.setUTCMonth(previousDateMonth + 12 / annualInstalments);
    return new Date(instalmentDate);
};
const aggregateInstalmentsByYear = (instalments) => {
    const groupedInstalments = groupBy(instalments, ({ date }) => date.getFullYear());
    return Object.values(groupedInstalments).reduce((allInstalments, instalmentsThisYear, index) => {
        const isLast = index === instalments.length - 1;
        const { remaining } = allInstalments[index - 1] ?? {};
        const payment = sumBy(instalmentsThisYear, 'payment');
        const interest = sumBy(instalmentsThisYear, 'interest');
        const decrease = isLast ? payment : payment - interest;
        const { date } = instalmentsThisYear[instalmentsThisYear.length - 1] ?? {};
        return [
            ...allInstalments,
            {
                remaining: isLast ? remaining - payment : 0,
                payment,
                interest,
                decrease,
                date,
            },
        ];
    }, []);
};

import omit from 'lodash/omit';
import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';

import { STATIC_SCREEN_BREAKPOINTS } from '@src/constants/display';
import { useLocation } from '@src/context/location';
import { DeviceType, ScreenWidthBreakpoints } from '@src/model';

import { useLeadOrigin } from './useLeadOrigin';

type Dimensions = { width: number; height: number };

export type ScreenSize = { type: DeviceType; width: number };

const evaluateScreenSize = (
  width: number,
  url = '',
  browser = '',
  inputBreakpoints: Partial<ScreenWidthBreakpoints> = {}
) => {
  const isMobileBrowser = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(browser);
  const isMobileURL = ['/m.', '.m.'].some(mobileTag => url?.includes(mobileTag));

  // const isTabletBrowser = /iPad/i.test(browser);
  const isMobile = isMobileURL || isMobileBrowser;

  const omitKeys: string[] = [...(isMobile ? [] : ['SMALL_MOBILE', 'LARGE_MOBILE'])];

  const breakpointsBase = omit({ ...STATIC_SCREEN_BREAKPOINTS, ...inputBreakpoints }, omitKeys);

  const breakpointEntries = Object.entries(breakpointsBase);

  const located = breakpointEntries.find(([, size], i) =>
    i === breakpointEntries.length - 1 ? width >= size : width <= size
  );
  return (located ?? breakpointEntries[0]!) as [DeviceType, number];
};

export const useDisplay = () => {
  const location = useLocation();
  const { origin: leadOrigin, loading } = useLeadOrigin();

  const [dimensions, setDimensions] = useState<Dimensions>({ width: 0, height: 0 });
  const [browser, setBrowser] = useState<string>('');

  useEffect(function handleResizeEvents() {
    const handleResize = throttle(
      () => setDimensions({ width: globalThis.innerWidth, height: globalThis.innerHeight }),
      1000 / 60
    );

    setBrowser(navigator.userAgent ?? '');
    handleResize();

    globalThis.addEventListener('resize', handleResize, { passive: true });

    return () => {
      globalThis.removeEventListener('resize', handleResize);
    };
  }, []);

  const { width } = dimensions;
  const [deviceType, deviceSize] = evaluateScreenSize(width, location?.href, browser);

  return {
    ...dimensions,
    leadOrigin,
    leadOriginLoading: loading,
    deviceType,
    breakpointWidth: deviceSize,
    isMobile: deviceType.includes('MOBILE'),
  };
};

import { createContext, useContext } from 'react';

import { Customer, CustomerInput } from '@src/model';

export type CustomerWithExtraData = Omit<Customer, 'events' | 'progress' | 'latestProgressEvent'> &
  Pick<CustomerInput, 'googleClickId' | 'utmFields'> & {
    jyskeBankDataConsent?: boolean;
  };

export type CustomerContext = {
  customer?: Partial<CustomerWithExtraData>;
  setCustomer?: (customer?: Partial<CustomerWithExtraData>) => void;
};

const customerContext = createContext<CustomerContext>({});

export const CustomerContextProvider = customerContext.Provider;

export const useCustomerContext = () => useContext(customerContext);

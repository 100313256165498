import round from 'lodash/round';
import { padThousands } from './string';
import { withVAT } from './vat';
const localization = {
    dk: { currency: 'kr.', placement: 'after', thousands: '.', decimal: ',' },
};
export const formatCurrency = (value = 0, options = {}, locale = 'dk') => {
    return addCurrency(formatNumber(value, options, locale), localization[locale]);
};
export const addCurrency = (value, options) => {
    const { currency, placement } = options;
    return `${placement === 'before' ? `${currency} ` : ''}${value}${placement === 'after' ? ` ${currency}` : ''}`;
};
export const formatNumber = (inputValue, options = {}, locale = 'dk') => {
    const { decimals, addVAT = false } = options;
    const { thousands, decimal } = localization[locale];
    const value = addVAT ? withVAT(inputValue, locale) : inputValue;
    const rounded = round(+value, 2);
    const fixedDecimals = decimals !== undefined ? rounded.toFixed(decimals) : rounded;
    const padded = padThousands(fixedDecimals, thousands);
    return padded.replace(/\.(\d\d?)$/g, `${decimal}$1`);
};

import { useEffect, useState } from 'react';

import { useLocationContext } from '@src/context/location';
import { localStore } from '@src/state/local';

export const LEAD_ORIGIN_NORLYS = 'norlys';
export const LEAD_ORIGIN_DISTRICT_HEATING_FLYER = 'dhflyer';

export type LeadOrigin = 'nordea' | 'norlys' | 'edc' | 'bodil' | 'cdm' | string;
export const ORIGINS: LeadOrigin[] = [LEAD_ORIGIN_NORLYS, 'nordea', 'edc', 'bodil', 'cdm'];

export const useLeadOrigin = () => {
  const [leadOrigin, setLeadOrigin] = useState<LeadOrigin>();
  const [loading, setLoading] = useState(true);

  const { location, props } = useLocationContext();
  const { href } = location ?? {};

  const updateLeadOrigin = (origin: LeadOrigin) => {
    localStore().set('leadOrigin', origin);
    setLeadOrigin(origin);
  };

  useEffect(() => {
    setLoading(false);

    const { brandOrganisation } = props;
    if (brandOrganisation) {
      updateLeadOrigin(brandOrganisation);
      return;
    }

    const parameters = new URLSearchParams(location?.search);
    const fromParameter = parameters?.get('leadOrigin') ?? undefined;

    if (fromParameter && ORIGINS.includes(fromParameter)) {
      updateLeadOrigin(fromParameter);
      return;
    }

    const fromURL = ORIGINS.find(name => href?.includes(name));
    if (fromURL) {
      updateLeadOrigin(fromURL);
      return;
    }

    const current = localStore().get('leadOrigin');
    setLeadOrigin(current ?? undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { origin: leadOrigin, loading };
};

export const linearInterpolation = ([x0, y0], [x1, y1]) => (x) => y0 + ((y1 - y0) / (x1 - x0)) * (x - x0);
export const roundToNearest = (n, rounding, direction = 'down') => {
    switch (direction) {
        case 'down':
            return Math.floor(n / rounding) * rounding;
        case 'up':
        default:
            return Math.ceil(n / rounding) * rounding;
    }
};

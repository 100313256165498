import { createContext, ReactNode, useContext } from 'react';

export type OrderedModal = { modal: JSX.Element; order: number };
export type Modals = Record<string, OrderedModal>;

export const showNextModal = (modals: Modals): ReactNode =>
  Object.values(modals).sort((a, b) => b.order - a.order)[0]?.modal;

export interface ModalContext {
  showModal: (modal: JSX.Element, key: string) => void;
  hideModal: (key: string) => void;
}
const modalContext = createContext<ModalContext>({
  showModal: () => {},
  hideModal: () => {},
});

export const useModalContext = () => useContext<ModalContext>(modalContext);
export const ModalContextProvider = modalContext.Provider;

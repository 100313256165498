import { DeepPartial, ResidenceHeatingInput, ResidenceInput } from '@src/model';

const isBaseResidenceFilled = (residence?: DeepPartial<ResidenceInput>) => !!(residence?.type && residence?.area);

const isWaterAndheatDistributionFilled = (residence?: DeepPartial<ResidenceInput>) =>
  !!(residence?.waterConsumption && residence?.heatDistribution);

const isHeatingFilled = (heating?: Partial<ResidenceHeatingInput> | null) =>
  !!(heating?.fuelType && heating?.annualUsage);

export const isResidenceFilled = (residence?: DeepPartial<ResidenceInput>): residence is ResidenceInput =>
  isResidenceInfoFilled(residence) &&
  isResidenceConsumptionFilled(residence) &&
  isWaterAndheatDistributionFilled(residence);

export const isResidenceInfoFilled = (residence?: DeepPartial<ResidenceInput>) =>
  !!(residence?.floors && isBaseResidenceFilled(residence));

export const isResidenceConsumptionFilled = (residence?: DeepPartial<ResidenceInput>) =>
  !!(residence?.areaHeated && residence?.energyExpenditure && isHeatingFilled(residence?.primaryHeating));

import { withoutVAT } from './shared';
export const calculateElectricitySellPrice = (price, reduction) => Math.max(withoutVAT(price) - reduction, 0);
export const calculateWeightingsOfTotal = (parameters) => {
    const { lhs, rhs, reduction = 0 } = parameters;
    const totalSum = lhs + rhs;
    const lhsRatio = lhs / totalSum;
    const rhsRatio = rhs / totalSum;
    const lhsSubtractedValue = lhsRatio * (reduction ?? 0);
    const rhsSubtractedValue = rhsRatio * (reduction ?? 0);
    return {
        lhs: Math.max(lhs - lhsSubtractedValue, 0),
        rhs: Math.max(rhs - rhsSubtractedValue, 0),
    };
};

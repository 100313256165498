export const calculatePaybackTime = (totalPrice, projectSavings) => {
    const { years, remainder } = calculatePaybackYears(totalPrice, projectSavings);
    const savedPerYear = projectSavings(years);
    const savedPerMonth = savedPerYear / 12;
    const months = Math.max(Math.ceil(savedPerMonth ? remainder / savedPerMonth : 0), 0);
    const paybackMonthsTotal = months + years * 12;
    const paybackFormatted = formatPaybackTime(paybackMonthsTotal);
    return { paybackMonths: paybackMonthsTotal, paybackFormatted };
};
const calculatePaybackYears = (totalPrice, projectSavings) => {
    let years = 0;
    let total = 0;
    let iterations = 1;
    while (total < totalPrice) {
        years += 1;
        iterations += 1;
        const savingsThisYear = projectSavings(years) ?? 0;
        if (savingsThisYear === 0 || iterations >= 20) {
            return { years: 20, remainder: 0 };
        }
        if (totalPrice - total < savingsThisYear) {
            years -= 1;
            break;
        }
        total += savingsThisYear;
    }
    return {
        years,
        remainder: totalPrice - total,
    };
};
export const formatPaybackTime = (paybackMonths) => {
    const years = Math.floor(paybackMonths / 12);
    const months = paybackMonths - years * 12;
    const yearsFormatted = !!years || !months ? `${years} år` : '';
    const monthsFormatted = months ? `${months} md` : '';
    return [yearsFormatted, monthsFormatted].filter(value => !!value).join(' ');
};

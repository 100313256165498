import { CSSProperties } from 'styled-components';

import { Device, DEVICE_TYPES, styleDeviceSize } from '@src/util';

export type PageMargin = 'left' | 'right' | 'both' | 'none';

export const defaultSideMargin = (placement: PageMargin) => ({
  ...DEVICE_TYPES.reduce(
    (acc, size) => ({
      ...acc,
      ...styleDeviceSize(size, padSides(placement, size)),
    }),
    {}
  ),
});

const padSides = (margin: PageMargin, device: Device): CSSProperties => ({
  paddingLeft: globalMargin(margin, 'left', device),
  paddingRight: globalMargin(margin, 'right', device),
});

const globalMargin = (margin: PageMargin, position: 'left' | 'right', device: Device) => {
  return ['both', position].includes(margin) ? marginByDevice(device) : '0';
};

const marginByDevice = (device: Device) => {
  const index = DEVICE_TYPES.indexOf(device) + 1;
  return `clamp(${4 * index}px, ${2 * index}%, ${60 * index}px)`;
};

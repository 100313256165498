import { useEffect, useState } from 'react';

import { useLocationContext } from '@src/context/location';
import { CustomerFinancingProvider } from '@src/model';
import { localStore } from '@src/state/local';

import { useCookie } from './useCookie';

export type FinancingProvider = CustomerFinancingProvider;

const PROVIDERS: FinancingProvider[] = ['jyskebank', 'nordea'];

export const useFinancingProvider = () => {
  const { cookie: financingProviderCookie, removeCookie } = useCookie('financingProvider');
  const [provider, setProvider] = useState<FinancingProvider | undefined>();

  const { location, props } = useLocationContext();
  const { href } = location ?? {};

  const savedProvider = localStore().get('financingProvider') ?? undefined;

  const updateProvider = (newProvider: string) => {
    setProvider(newProvider as FinancingProvider);
    localStore().set('financingProvider', newProvider);
    removeCookie();
  };

  useEffect(() => {
    const { brandOrganisation } = props;

    const providersFromUrl = PROVIDERS.filter(item => href?.includes(item));

    const candidates = [
      brandOrganisation,
      ...providersFromUrl,
      financingProviderCookie,
      savedProvider,
      'jyskebank',
    ] as FinancingProvider[];

    for (const candidate of candidates) {
      if (isValidProvider(candidate)) {
        updateProvider(candidate);
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { provider };
};

const isValidProvider = (provider?: FinancingProvider) => !!provider && PROVIDERS.includes(provider);

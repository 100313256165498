import round from 'lodash/round';
import sumBy from 'lodash/sumBy';
import { DEGREE_DAYS_WEIGTED } from '../constants/datasheet';
import { fuelOptions } from '../constants/heating';
import { findConstantForHeating } from '../lib/constant';
import { weighHeatedArea } from './area';
import { energyByYearAndArea } from './energyByYearAndArea';
export const calculateHeatingInput = (heating, energyOptions = fuelOptions) => calculateHeatingOutput(heating ? { ...heating, efficiency: 1 } : heating, energyOptions);
export const calculateHeatingOutput = (heating, energyOptions = fuelOptions) => {
    const { annualUsage, efficiency } = heating ?? {};
    if (!annualUsage) {
        return 0;
    }
    const optionsValue = findConstantForHeating(heating, energyOptions);
    return (optionsValue?.calorific || 0) * annualUsage * (efficiency ?? 1);
};
export const aggregateHeatingInput = (heatings, energyOptions = fuelOptions) => sumBy(heatings, heating => calculateHeatingInput(heating, energyOptions));
export const aggregateHeatingOutput = (heatings, energyOptions = fuelOptions) => sumBy(heatings, heating => calculateHeatingOutput(heating, energyOptions));
export const calculateResidenceHeatingRequired = (residence, energyOptions = fuelOptions) => {
    const output = aggregateHeatingOutput([residence.primaryHeating ?? undefined, residence.secondaryHeating ?? undefined], energyOptions);
    return output / DEGREE_DAYS_WEIGTED;
};
export const calculateHeatLoadPerArea = (residence, heatingEffect) => {
    const { primaryHeating } = residence;
    const heatedArea = weighHeatedArea(residence);
    if (!heatedArea) {
        return 0;
    }
    const energyIn = aggregateHeatingOutput([primaryHeating]);
    const heatLoad = energyIn / DEGREE_DAYS_WEIGTED;
    return round((1000 * (heatingEffect ?? heatLoad)) / heatedArea, 2);
};
export const estimatePrimaryHeating = (fuelType, areaHeated, builtYear, energyOptions = fuelOptions) => {
    const constant = findConstantForHeating({ fuelType }, energyOptions);
    const price = constant?.price ?? 0;
    const annualUsage = estimatePrimaryHeatingUsage(fuelType, areaHeated, builtYear, energyOptions);
    return {
        price,
        annualUsage,
        fuelType,
        efficiency: estimateEfficiencyByFuelType(fuelType),
    };
};
export const estimatePrimaryHeatingUsage = (fuelType, areaHeated, builtYear, energyOptions = fuelOptions) => {
    const usageByLabelPerArea = energyByYearAndArea(builtYear, areaHeated);
    const usageByLabel = (usageByLabelPerArea * areaHeated) / 1000;
    const constant = findConstantForHeating({ fuelType }, energyOptions);
    const { calorific } = constant ?? {};
    if (!calorific) {
        return 0;
    }
    return (usageByLabel * DEGREE_DAYS_WEIGTED) / calorific;
};
const estimateEfficiencyByFuelType = (fuelType) => {
    switch (fuelType) {
        case 'electricity':
            return 1;
        case 'oil':
            return 0.96;
        case 'straw':
            return 0.88;
        case 'wood':
            return 0.85;
        case 'districtHeating':
            return 1;
        case 'gas':
        default:
            return 0.94;
    }
};

export const fetchRequest = async (input, init) => {
    const response = await fetch(input, init);
    try {
        const data = await response.json();
        return data;
    }
    catch (error) {
        return undefined;
    }
};

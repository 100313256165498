import { useEffect, useState } from 'react';

import { useLocation } from '@src/context/location';
import { useEnvironment } from '@src/hooks/useEnvironment';
import { Script } from '@src/model';
import { localStore } from '@src/state/local';

export const useCustomerChatScripts = (): Script[] => {
  const [useChat, setUseChat] = useState(false);
  const { href } = useLocation() ?? {};
  const isDebug = Boolean(localStore().get('debug'));

  const { isLocalhost, isStaging } = useEnvironment();

  useEffect(() => {
    const isProduction = isDebug || (!isLocalhost && !isStaging);

    const timeout = setTimeout(() => setUseChat(isProduction), 1e3);
    return () => clearTimeout(timeout);
  }, [isLocalhost, isStaging, href, isDebug]);

  if (!useChat) {
    return [];
  }

  return [{ src: '/scripts/relatel.js' }];
};

export const DEFAULT_CONSUMPTION_PROFILE_KEY = 'homeMostDays';
export const consumptionProfiles = {
    homeAt17: [
        0.0159, 0.0159, 0.0159, 0.0159, 0.0212, 0.0435, 0.0652, 0.0742, 0.0583, 0.0371, 0.0318, 0.0212, 0.0212, 0.0212,
        0.0318, 0.0424, 0.0782, 0.105, 0.0954, 0.0595, 0.0492, 0.0304, 0.0261, 0.0233,
    ],
    homeEveryDay: [
        0.0114, 0.0114, 0.0114, 0.0114, 0.0151, 0.031, 0.0466, 0.053, 0.0631, 0.0551, 0.0584, 0.0509, 0.0509, 0.0509,
        0.0584, 0.066, 0.0773, 0.075, 0.0682, 0.0425, 0.0352, 0.0217, 0.0186, 0.0167,
    ],
    homeMostDays: [
        0.0133, 0.0133, 0.0133, 0.0133, 0.0177, 0.0364, 0.0546, 0.0621, 0.0572, 0.0478, 0.0475, 0.0387, 0.0387, 0.0387,
        0.0475, 0.0564, 0.078, 0.0878, 0.0799, 0.0498, 0.0412, 0.0255, 0.0218, 0.0195,
    ],
    homeInMorning: [
        0.0135, 0.0135, 0.0135, 0.0135, 0.018, 0.0368, 0.0552, 0.0629, 0.0664, 0.0654, 0.0609, 0.0519, 0.0434, 0.0264,
        0.027, 0.0359, 0.0663, 0.089, 0.0809, 0.0504, 0.0417, 0.0258, 0.0221, 0.0198,
    ],
    homeInAfternoon: [
        0.014, 0.014, 0.014, 0.014, 0.0186, 0.0381, 0.0572, 0.0651, 0.0512, 0.0326, 0.0279, 0.0361, 0.0449, 0.0449, 0.0542,
        0.0635, 0.0686, 0.0921, 0.0837, 0.0522, 0.0432, 0.0267, 0.0229, 0.0205,
    ],
};
export const DEFAULT_CONSUMPTION_PROFILE = consumptionProfiles[DEFAULT_CONSUMPTION_PROFILE_KEY];

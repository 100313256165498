import { CSSProperties } from 'styled-components';

import { BodilColor } from '@src/branding';
import { NorlysFont } from '@src/branding/font';
import { InputSize } from '@src/model/input';

export const inputWidth = (size: InputSize) => {
  switch (size) {
    case 'small':
      return '144px';
    case 'large':
      return '360px';
    case 'middle':
    default:
      return '240px';
  }
};

export const inputHeight = (size: InputSize) => {
  switch (size) {
    case 'small':
      return '24px';
    case 'large':
      return '40px';
    case 'middle':
    default:
      return '32px';
  }
};

export const numberInputPaddingMobile = (size: InputSize) => {
  const height = '32px';
  const paddingLeft = '5px';

  switch (size) {
    case 'small':
      return { padding: `calc(${height} - 32px)`, paddingLeft };
    case 'large':
      return { padding: `calc(${height} - 24px)`, paddingLeft };
    case 'middle':
      return { padding: `calc(${height} - 28px)`, paddingLeft };
  }
};

export const textInputPaddingMobile = (size: InputSize) => {
  const height = '32px';
  const paddingLeft = '16px';

  switch (size) {
    case 'small':
      return { padding: `calc(${height} - 28px)`, paddingLeft };
    case 'large':
      return { padding: `calc(${height} - 20px)`, paddingLeft };
    case 'middle':
      return { padding: `calc(${height} - 24px)`, paddingLeft };
  }
};

export const inputHeightAddress = (size: InputSize) => {
  switch (size) {
    case 'small':
      return '32px';
    case 'large':
      return '48px';
    case 'middle':
    default:
      return '40px';
  }
};

export const inputAddressHouseSize = (size: InputSize) => {
  switch (size) {
    case 'small':
      return '12px';
    case 'large':
      return '28px';
    case 'middle':
    default:
      return '20px';
  }
};

export const inputHeightNorlys = (size: InputSize) => {
  switch (size) {
    case 'small':
      return '42px';
    case 'large':
      return '58px';
    case 'middle':
      return '50px';
  }
};

export const numberInputPaddingNorlys = (size: InputSize) => {
  const height = inputHeightNorlys(size);
  switch (size) {
    case 'small':
      return `calc(${height} - 26px)`;
    case 'large':
      return `calc(${height} - 42px)`;
    case 'middle':
      return `calc(${height} - 34px)`;
  }
};

export const textInputPaddingNorlys = (size: InputSize) => {
  const height = inputHeightNorlys(size);
  switch (size) {
    case 'small':
      return `calc(${height} - 22px)`;
    case 'large':
      return `calc(${height} - 38px)`;
    case 'middle':
      return `calc(${height} - 30px)`;
  }
};

export const inputSizeStyleAddress = (size: InputSize) => ({
  width: inputWidth(size),
  height: inputHeightAddress(size),
});

export const inputSizeStyle = (size: InputSize) => ({
  width: inputWidth(size),
  height: inputHeight(size),
});

export const inputSizeStyleNorlys = (size: InputSize) => ({
  width: inputWidth(size),
  height: inputHeightNorlys(size),
});

export const labelStyleNorlys = () =>
  ({
    position: 'absolute',
    left: '11px',
    top: '7px',
    zIndex: 2,
    ...NorlysFont.style('default', 12),
    color: BodilColor.greyscale[10],
  }) as CSSProperties;

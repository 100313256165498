import { createContext, useContext } from 'react';

import { DeviceType } from '@src/model';

export type DisplayContext = {
  leadOrigin?: string;
  leadOriginLoading?: boolean;

  deviceType: DeviceType;
  isMobile: boolean;
  breakpointWidth: number;

  width: number;
  height: number;
};

const displayContext = createContext<DisplayContext>({
  deviceType: 'DESKTOP',
  width: 0,
  height: 0,
  breakpointWidth: 0,
  isMobile: false,
  leadOrigin: undefined,
  leadOriginLoading: false,
});

export const DisplayContextProvider = displayContext.Provider;

export const useDisplayContext = () => useContext<DisplayContext>(displayContext);

import { formatAddress } from "./util";
import { addressByIdUrl, addressesUrl, DAWA_OPTIONS } from './constants';
import { fetchRequest } from './util/request';
export const findAddress = async (address) => {
    const search = formatAddress(address);
    const url = encodeURI(addressesUrl(search));
    const addressData = await fetchRequest(url, DAWA_OPTIONS);
    return matchDataToAddress(addressData, search);
};
export const findAddresses = async (search) => {
    const url = encodeURI(addressesUrl(search));
    return fetchRequest(url, DAWA_OPTIONS);
};
export const findAddressById = async (id) => fetchRequest(addressByIdUrl(id), DAWA_OPTIONS);
export const addressByStringLookup = async (search) => {
    const url = encodeURI(addressesUrl(search));
    const addressData = await fetchRequest(url, DAWA_OPTIONS);
    return matchDataToAddress(addressData, search)?.id;
};
export const matchDataToAddress = (addresses = [], address = '') => {
    return addresses.find(data => data.betegnelse === address) ?? addresses[0];
};
export const toAddressFromDAWASuggestion = (input, includeCoordinates = false) => toAddressFromDAWAAddress(input?.data, includeCoordinates);
export const toAddressFromDAWAAddress = (input, includeCoordinates = false) => {
    if (!input) {
        return undefined;
    }
    return {
        id: input.id,
        street: input.vejnavn,
        houseNumber: input.husnr,
        postalCode: input.postnr,
        city: input.postnrnavn,
        ...(input.dør ? { door: input.dør } : {}),
        ...(input.etage ? { floor: input.etage } : {}),
        // Dawa swaps lat,lng to match y,x
        ...(includeCoordinates && input.y ? { latitude: Number(input.y) } : {}),
        ...(includeCoordinates && input.x ? { longitude: Number(input.x) } : {}),
    };
};
export const toAddressFromAddressDescription = async (label) => {
    const data = await fetchRequest(addressesUrl(label));
    return toAddressFromDAWAAddress(data?.[0]);
};
export const isDAWAAddress = (data) => data.adgangsadresseid !== undefined;

import { annualHotWaterConsumption } from "../constants/hotWater";
import { linearInterpolation } from "../../util";
import { DEGREE_DAYS_WEIGTED } from '../constants/datasheet';
import { fuelOptions } from '../constants/heating';
import { HEAT_PUMP_HOURLY_CONSUMPTION, HEAT_PUMP_MONTHLY_CONSUMPTION } from '../constants/heatPump';
import { aggregateHeatingOutput, calculateResidenceHeatingRequired } from '../residence/heating';
const SINGLE_CIRCUIT_HEATING = ['floor', 'radiator'];
export const calculateHeatDistributionValue = (effect, residenceHeatDistribution) => {
    if (!residenceHeatDistribution) {
        return 0;
    }
    const { heatDistribution, heatingCircuits, radiatorFloorPercentage } = residenceHeatDistribution ?? {};
    if (heatDistribution === 'radiatorAndFloor' && heatingCircuits === 1) {
        return effect['radiator'];
    }
    if (SINGLE_CIRCUIT_HEATING.includes(heatDistribution ?? 'radiator')) {
        return effect[heatDistribution];
    }
    const { floor, radiator } = effect;
    return linearInterpolation([0, radiator], [100, floor])(radiatorFloorPercentage ?? 50);
};
export const calculateHeatPumpOutputRequired = (residence, heatPump, chosenHeating, energyOptions = fuelOptions) => {
    if (!heatPump || !residence || !heatPump.scop) {
        return 0;
    }
    if (heatPump.copHotWater) {
        return calculateHeatPumpOutputRequiredWithHotWater(residence, heatPump, chosenHeating, energyOptions);
    }
    const heating = chosenHeating || calculateResidenceHeatingRequired(residence, energyOptions);
    const scop = calculateHeatDistributionValue(heatPump.scop, residence);
    return (1.1 * (heating * DEGREE_DAYS_WEIGTED)) / scop;
};
export const calculateHeatPumpOutputRequiredWithHotWater = (residence, heatPump, chosenHeating, energyOptions = fuelOptions) => {
    if (!heatPump || !residence || !heatPump.scop || !heatPump.copHotWater) {
        return 0;
    }
    const { energyRoomHeating, energyHotWater } = splitResidenceHeatingContributions(residence, chosenHeating, energyOptions);
    const scop = calculateHeatDistributionValue(heatPump.scop, residence);
    const energySecondaryHeating = aggregateHeatingOutput([residence.secondaryHeating], energyOptions);
    const contributionRoomHeating = (energyRoomHeating + energySecondaryHeating) / (0.99 * scop);
    const contributionHotWater = energyHotWater / (heatPump.copHotWater ?? 1);
    return contributionRoomHeating + contributionHotWater;
};
export const splitResidenceHeatingContributions = (residence, chosenHeating, energyOptions = fuelOptions) => {
    const { primaryHeating, residents, waterConsumption } = residence ?? {};
    const output = aggregateHeatingOutput([primaryHeating], energyOptions);
    const totalEnergyHeating = chosenHeating ? chosenHeating * DEGREE_DAYS_WEIGTED : output;
    const consumedPerPerson = annualHotWaterConsumption[waterConsumption ?? 'medium'];
    const energyHotWater = consumedPerPerson * (residents ?? 3);
    const energyRoomHeating = totalEnergyHeating - energyHotWater;
    return { energyHotWater, energyRoomHeating };
};
export const extrapolateHeatPumpConsumptionByMonth = (residenceHeatingInformation, outdoorUnit, chosenHeating, extraHeating = 0, energyOptions = fuelOptions) => {
    const heatingRequired = outdoorUnit
        ? calculateHeatPumpOutputRequired(residenceHeatingInformation, outdoorUnit, chosenHeating, energyOptions)
        : 0;
    const totalRequired = heatingRequired + extraHeating;
    return totalRequired
        ? HEAT_PUMP_MONTHLY_CONSUMPTION.map(value => HEAT_PUMP_HOURLY_CONSUMPTION.map(innerValue => value * innerValue * totalRequired))
        : undefined;
};

import styled from 'styled-components';

import { BodilColor } from '@src/branding';
import { Heading, Text } from '@src/components/Typography';
import { ToasterConfig, ToasterType } from '@src/model';

const Wrapper = styled.div<{ backgroundColor: string }>(({ backgroundColor }) => ({
  display: 'flex',
  marginTop: '30px',
  width: 'fit-content',
  height: 'fit-content',
  minWidth: 280,
  maxWidth: 340,
  minHeight: 32,
  border: `2px solid ${backgroundColor}`,
  backgroundColor: BodilColor.base.white,
  whiteSpace: 'pre-wrap',
  borderRadius: '8px',
  cursor: 'default',
  userSelect: 'none',
  zIndex: 99999,
  [`:hover > ${TextWrapper}`]: {
    marginLeft: '32px',
  },
}));

const Line = styled.div<{ backgroundColor: string }>(({ backgroundColor }) => ({
  backgroundColor,
  height: 'auto',
  width: 5,
  margin: '8px',
  borderRadius: '8px',
}));

const TextWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '70%',
  margin: '8px 0 8px 16px',
  transition: '0.3s',
});

const toasterColor: Record<ToasterType, string> = {
  info: BodilColor.ui.attention,
  error: BodilColor.ui.error,
  success: BodilColor.ui.success,
};

export const Toaster = ({ type, header, message }: ToasterConfig) => {
  const color = toasterColor[type];

  return (
    <Wrapper backgroundColor={color}>
      <Line backgroundColor={color} />
      <TextWrapper>
        {header && <Heading style={{ marginBottom: '4px' }}>{header}</Heading>}
        {message && (
          <Text level={2} style={{ marginBottom: '4px' }}>
            {message}
          </Text>
        )}
      </TextWrapper>
    </Wrapper>
  );
};

import { createContext, useContext } from 'react';

import { PageProps } from '@src/model';

type LocationContext = { location?: PageProps['location']; props: Partial<PageProps> };

const locationContext = createContext<LocationContext>({
  props: {},
});

export const useLocationContext = () => useContext<LocationContext>(locationContext);
export const useLocation = () => useLocationContext().location;

export const LocationContextProvider = locationContext.Provider;

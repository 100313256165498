import { useEffect, useState } from 'react';
import { OperationResult, UseMutationState, UseQueryState } from 'urql';

import { useToasters } from '@src/context/toaster';
import { getFirstError } from '@src/graphql';
import { ToasterConfig } from '@src/model';

type ToasterText = Pick<ToasterConfig, 'header' | 'message'>;

type RequestToasterConfig = {
  error?: ToasterText;
  success?: ToasterText;
};

export const useQueryToaster = <T>(result: UseQueryState<T> | UseMutationState<T>, config: RequestToasterConfig) => {
  const [fetched, setFetched] = useState<boolean>(false);
  const showToaster = useGraphQLToaster(config);

  useEffect(() => {
    if (result.fetching) {
      setFetched(false);
      return;
    }
    if (fetched) {
      return;
    }
    if (getFirstError(result)?.type === 'UNAUTHORIZED') {
      return;
    }
    setFetched(true);
    showToaster(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);
};

export const useGraphQLToaster = <T>(config: RequestToasterConfig = {}) => {
  const showToaster = useToasters();

  return (result: UseQueryState<T> | UseMutationState<T> | OperationResult<T>, format?: () => RequestToasterConfig) => {
    const additionalConfig = format?.() ?? {};

    if (getFirstError(result)?.type === 'UNAUTHORIZED') {
      return;
    }

    if (result.error && config.error) {
      showToaster({ ...config.error, type: 'error', ...additionalConfig.error });
      return;
    }

    if (result.data && config.success) {
      showToaster({ ...config.success, type: 'success', ...additionalConfig.success });
    }
  };
};

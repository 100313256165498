export const heatSourceToFuelType = (heatSource) => {
    switch (heatSource) {
        case 'gasRadiator':
            return 'gas';
        case 'heatPump-air-to-air':
        case 'heatPump-air-to-water':
            return 'electricity';
        case 'oilStove':
        case 'petroleumOven':
            return 'oil';
        case 'fireplace':
        case 'massOven':
        case 'stoveCertified':
        case 'stoveUncertified':
        case 'tiledStove':
            return 'wood';
        default:
            return undefined;
    }
};
